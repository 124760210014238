import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './Global-Styles.css';
import './styles/tailwind_build.css';
import './styles/suma.css';
import './styles/progress-wheel.css';
import * as serviceWorker from './serviceWorker';
import { StripeProvider } from 'react-stripe-elements';
import { Auth0Provider } from './react-auth0-spa';
import config from './auth_config.json';

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
  );
};

const redirect = `${window.location.origin}/callback`;

const Store = () => {
  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_APIKEY}>
      <App />
    </StripeProvider>
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={redirect}
    onRedirectCallback={onRedirectCallback}
  >
    <Store />
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
