import styles from './atoms/ProgressCircle/ProgressCircle.module.css';

import * as Yup from 'yup';

import { CardElement, injectStripe } from 'react-stripe-elements';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';

import { IMaskInput } from 'react-imask';
import Modal from 'react-modal';
import ReactEcharts from 'echarts-for-react';
import RewardIcon from '../assets/icons/rewards.png';
import axios from 'axios';
import back from '../assets/icons/close.png';
import desktopDevice from '../DesktopFirstDevice';
import device from '../Device';
import hamburgerIcon from '../assets/icons/hamburger.svg';
import lockIcon from '../assets/icons/Lock.svg';
import selectedIcon from '../assets/icons/Selected.svg';
import styled from 'styled-components/macro';
import unselectedIcon from '../assets/icons/Unselected.svg';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Button from './atoms/Button';
import classNames from 'classnames';
import Typography from './atoms/Typography';
import ProgressWheel from './atoms/ProgressWheel/ProgressWheel';

const variant = 'base';

const CCModal = (props) => {
  const utmParams = '?utm_source=chipinpage&utm_medium=chipin&utm_campaign=chipin';
  const ccInput = useRef();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [gifter, setGifter] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [validEmail, setValidEmail] = useState(true);
  const [formCompleted, setFormCompleted] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [recieveReelMail, setRecieveReelMail] = useState(false);
  const [agreementError, setAgreementError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('none');
  const [isEnabled, setIsEnabled] = useState(false);

  const [progress, setProgress] = useState('none');
  const [cardError, setCardError] = useState('');

  const cleanMobilePhone = (mobilePhone) => {
    if (mobilePhone) {
      let mobileNumber = mobilePhone.replace(/([^0-9])/g, '');
      if (mobileNumber.length === 10) mobileNumber = '1' + mobileNumber;
      if (mobileNumber.startsWith('+') === false) mobileNumber = '+' + mobileNumber;
      return mobileNumber;
    } else {
      return null;
    }
  };

  const openModal = (event, value) => {
    event.preventDefault();

    setModalIsOpen(true);
  };

  const agreeToAmount = (e) => {
    setAgreement(!agreement);
    setAgreementError(!e.target.checked);
  };

  const agreeToReelMail = () => {
    setRecieveReelMail(!recieveReelMail);
  };

  const handleBlur = () => {
    console.log('[blur]');
  };
  const handleChange = (change) => {
    setCardError(change.error && change.error.message ? change.error.message : '');

    setFormCompleted(change.complete);
  };
  const handleClick = () => {
    console.log('[click]');
  };
  const handleFocus = () => {
    console.log('[focus]');
  };
  const handleReady = () => {
    console.log('[ready]');
  };

  const createOptions = () => {
    return {
      style: {
        base: {
          fontSize: '16px',
          color: '#0a0a0a',
          letterSpacing: '0.025em',
          fontFamily: 'proxima-nova',
          '::placeholder': {
            color: '#0a0a0a',
          },
          padding: '50px',
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
  };

  const getGoal = async () => {
    const result = await axios.post(`${process.env.REACT_APP_API}`, {
      data: {
        userId: props.userId,
        goalId: props.goalId,
      },
    });
    if (result.data.body.message === 'Goal exists') {
      let goal = JSON.parse(result.data.body.goalStatus);

      let paid = JSON.parse(result.data.body.transactionsTotal);

      const discount =
        goal.target.discount && goal.target.discount.amount ? goal.target.discount.amount : 0;

      const shipping =
        goal.target.shipping && goal.target.shipping.amount ? goal.target.shipping.amount : 0;

      let itemPrice = goal.target.amount.amount + goal.target.tax.amount + shipping - discount;

      let percentage = Math.ceil((paid / itemPrice) * 100);

      const totalTargetToPay = itemPrice.toFixed(0);

      props.chipInSuccess();

      setProgress(percentage);

      getBarWidthAndColor();
    } else {
    }
  };

  const getBarWidthAndColor = () => {
    if (props.progress && progress != '') {
      let boostProgess = progress - props.progress.userProgress.percentage;
      let totalProgress = progress;
      let progressWithoutBoost = props.progress.userProgress.percentage;

      return [
        [
          (props.progress.userProgress.percentage - props.progress.withChipInProgress.percentage) /
            100,
          '#f39a7c',
        ],
        [props.progress.userProgress.percentage / 100, '#dd5a57'],
        [1, '#eee8e2'],
      ];
    } else {
      return [
        [props.progress.userProgress.percentage / 100, '#f39a7c'],
        [1, '#eee8e2'],
      ];
    }
  };

  const closeModal = () => {
    window.location.reload();

    // if (this.state.success === true) {
    //   window.location.href = `${process.env.REACT_APP_REDIRECT_LINK}?utm_source=chip%20in&utm_campaign=awareness&utm_content=chipin_modal_xedout`;
    // }
  };

  const getProductImage = () => {
    if (props.userGoal) {
      return props.userGoal.product.pictures.default.large;
    } else {
      return '';
    }
  };

  const capitalize = (s) => {
    if (s) {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    } else {
      return '';
    }
  };

  const handleSubmit = async ({ email, name, phone }) => {
    if (agreement === true && validEmail === true) {
      if (name.trim().length != 0 && email.trim().length != 0) {
        setIsEnabled(false);
        setProcessing(true);
        try {
          let { token } = await props.stripe.createToken({
            type: 'card',
            address_country: 'US',
            name,
          });

          let chipIn = props.value[0] * 100;
          let fee = ((props.value[0] * 3) / 100) * 100;

          const result = await axios.post(`${process.env.REACT_APP_API}`, {
            data: {
              token: token,
              chipIn: chipIn,
              fee: fee,
              goalImage: props.userGoal.product.pictures.default.large,
              chipper: name,
              email: email,
              phone: cleanMobilePhone(phone),
              message: props.value[1] === '' ? "Here's a little gift for you!" : props.value[1],
              user: {
                name: props.name,
                userId: props.userId,
                goalId: props.goalId,
              },
            },
          });

          setIsEnabled(true);
          setProcessing(false);

          if (result.data.body.message === 'Charged!') {
            window.dataLayer.push({
              event: `app.event`,
              eventCategory: 'chip-in',
              eventType: 'success',
              eventName: 'success',
              eventValue: Math.floor((chipIn + fee) / 100),
            });
            await getGoal();
            await props.chipInSuccess();

            setSuccess(true);
          } else {
            setProcessing(false);
            if (result.data.body.message === 'Bad token') {
              setError('Invalid card');
            } else {
              setError(result.data.body.message);
            }
          }
        } catch (err) {
          console.log('subnot', err);
          setIsEnabled(true);
          throw err;
        }
      } else {
        if (gifter === '') {
          setGifter('');
        }
        setError('Incomplete fields');
      }
    } else {
      setAgreementError(true);
    }
  };

  const getOption = () => ({
    grid: {
      z: 1,
      show: false,
      left: '0%',
      right: '0%',
      top: '0%',
      containLabel: true,
      splitLine: {
        show: true,
      },
    },
    xAxis: [
      {
        type: 'category',
        data: [],
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        splitArea: {
          interval: 'auto',
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    toolbox: {
      show: false,
    },
    series: [
      {
        type: 'gauge',
        startAngle: 240,
        endAngle: -60,
        radius: '90%',

        splitNumber: 30,
        z: 4,
        axisTick: {
          show: false,
        },
        splitLine: {
          length: 24, // separator length
          lineStyle: {
            width: 3,
            color: '#fdfcfa',
          },
        },
        axisLabel: {
          show: false,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            opacity: 0,
          },
        },
        detail: {
          show: false,
        },
        data: [{ value: progress }],
      },
      {
        type: 'gauge',
        startAngle: 240,
        endAngle: -60,

        z: 3,
        min: 0,
        max: 100,
        splitNumber: 12,
        radius: '92%',
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        pointer: {
          width: '60px',
          z: 5,
          itemStyle: {
            color: '#d7ccc0',
          },
        },
        itemStyle: {
          color: '#d7ccc0',
          fontSize: 12,
        },
        detail: {
          show: false,
        },
        data: [{ value: progress }],
      },
      {
        type: 'gauge',
        z: 2,
        min: 0,
        max: 100,
        radius: '90%',
        startAngle: 240,
        endAngle: -60,
        splitNumber: 4,
        axisLine: {
          lineStyle: {
            color: getBarWidthAndColor(),
            width: 24, // bar width
            opacity: 1,
          },
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    graphic: [
      {
        type: 'group',
        right: 'center',
        bottom: '73px',
        z: 7,
        children: [
          {
            type: 'circle',
            z: 8,
            shape: {
              cx: 60,
              cy: 70,
              r: 132,
            },
            style: {
              fill: '#fdfcfa',
              stroke: '#d7ccc0',
              lineWidth: 2,
            },
          },
        ],
      },
    ],
  });
  let modalContent = () => {
    const chipInChanges =
      props && props.ldClient ? props.ldClient.allFlags()['chip-in-changes'] : false;
    if (success === false) {
      return (
        <Formik
          initialValues={{ name: '', email: '', phone: '' }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            phone: Yup.string().required('Required').length(19),
            name: Yup.string().required('Required'),
            email: Yup.string().required('Required').email('Enter a valid email'),
          })}
        >
          {({ isValid, isSubmitting, errors, touched, values, setFieldValue }) => (
            <Form className="chip-info-wrapper">
              <CloseStripeFormDesktop
                type="image"
                src={hamburgerIcon}
                onClick={closeModal}
              ></CloseStripeFormDesktop>

              <CloseStripeFormMobile
                type="image"
                src={back}
                onClick={closeModal}
              ></CloseStripeFormMobile>
              <ModalTitle className="heading">Chip in information</ModalTitle>
              <ModalText>
                <p>
                  Enter your card info to contribute ${props.value[0]} towards{' '}
                  {props.value[2] !== 'Friend' ? `${capitalize(props.value[2])}'s` : `your friend`}{' '}
                  Goal.
                </p>
              </ModalText>
              <InputFrom>
                <NameWrapper>
                  <label>CARDHOLDER’S NAME</label>
                  {/* <img src={userIcon} alt="User Icon"></img> */}
                  <Field
                    className="form-input"
                    name="name"
                    style={{ border: errors.name ? '2px solid red' : 'none' }}
                    type="text"
                    placeholder="e.g Jane Doe"
                  ></Field>

                  <div className="error-field">
                    <ErrorMessage name="name" />
                  </div>
                </NameWrapper>
                <NameWrapper>
                  <label>EMAIL ADDRESS</label>
                  {/* <img src={emailIcon} alt="Email Icon"></img> */}
                  <Field
                    className="form-input"
                    name="email"
                    style={{ border: errors.email ? '2px solid red' : 'none' }}
                    type="text"
                    placeholder="Enter here"
                    // onChange={(event) => cardEmail(event)}
                    // onBlur={(event) => validateEmail(event)}
                    // valid={validEmail}
                  ></Field>
                  <div className="error-field">
                    <ErrorMessage name="email" />
                  </div>
                </NameWrapper>

                <NameWrapper>
                  <label>PHONE NUMBER</label>

                  <Field
                    name="phone"
                    render={(fieldProps) => (
                      <IMaskInput
                        inputMode="tel"
                        style={{ border: errors.phone ? '2px solid red' : 'none' }}
                        className="phone-input"
                        {...{
                          mask: '{+1} (000) 000 - 0000',
                        }}
                        value={values.phone}
                        onAccept={(value, mask) => {
                          setFieldValue('phone', value);
                        }}
                        placeholder="+1 (999) 999 - 9999"
                      />
                    )}
                  />
                  <div className="error-field">
                    <ErrorMessage name="phone">
                      {(msg) => {
                        return (
                          <div>
                            {msg.startsWith('phone must') ? 'Phone must be exactly 10 digits' : msg}
                          </div>
                        );
                      }}
                    </ErrorMessage>
                  </div>
                </NameWrapper>

                <NameWrapper>
                  <label>CARD INFORMATION</label>
                  <CardElement
                    className={`${cardError ? 'credit-card-error' : ''}`}
                    ref={ccInput}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onReady={handleReady}
                    {...createOptions()}
                    required
                  />

                  {cardError && <div className="error-field">{cardError}</div>}
                </NameWrapper>
              </InputFrom>

              <AmountInfo>
                {props.value && (
                  <span>
                    {' '}
                    The total amount charged will be $
                    {(props.value[0] + (props.value[0] * 3) / 100).toFixed(2)}{' '}
                  </span>
                )}
                which includes your ${props.value[0]} gift + the 3% card processing fee of $
                {((props.value[0] * 3) / 100).toFixed(2)}.
              </AmountInfo>
              <ErrorText error={error}>{error}</ErrorText>
              <AmountAgreement>
                <Checkbox
                  onChange={(e) => {
                    agreeToAmount(e);
                  }}
                  type="checkbox"
                  name="agreement"
                  id="agreement"
                ></Checkbox>
                <RoundCheckbox htmlFor="agreement">
                  <img src={!agreement ? unselectedIcon : selectedIcon} alt="Circle Toggle Icon" />
                </RoundCheckbox>
                <SpanText agreementError={agreementError}>
                  I confirm the charges and that I have read and agree to Suma’s{' '}
                  <a target="_blank" href="https://sumawealth.com/terms">
                    Terms of Use
                  </a>{' '}
                  &{' '}
                  <a target="_blank" href="https://sumawealth.com/privacy-policy">
                    Privacy Policy
                  </a>
                </SpanText>
                <Required agreementError={agreementError}>*</Required>
              </AmountAgreement>
              <EmailAgreement>
                <Checkbox type="checkbox" name="emailAgreement" id="emailAgreement"></Checkbox>
                <RoundCheckbox
                  htmlFor="emailAgreement"
                  onClick={(e) => {
                    agreeToReelMail();
                  }}
                >
                  <img
                    src={!recieveReelMail ? unselectedIcon : selectedIcon}
                    alt="Circle Toggle Icon"
                  />
                </RoundCheckbox>
                <EmailText>I would like to recieve emails from Suma Savings</EmailText>
              </EmailAgreement>
              <SubmitStripe type="submit">
                <Button loading={processing}>CONFIRM</Button>
              </SubmitStripe>
              <div className="items-center justify-center flex my-2">
                <div className="items-center flex flex-row">
                  <div className="h-4 w-4"></div>
                  <img className="lg:mr-4" src={lockIcon} alt="Lock Icon"></img>
                </div>
                <div className="items-center flex flex-row">
                  <span>Encrypted with 128-bit SSL. 100% secure.</span>
                </div>
              </div>
              {/* <SecureTag>
              </SecureTag> */}
            </Form>
          )}
        </Formik>
      );
    } else if (success === true) {
      return (
        <SuccessModal>
          <CloseStripeFormDesktop
            type="image"
            src={hamburgerIcon}
            onClick={closeModal}
          ></CloseStripeFormDesktop>

          <CloseStripeFormMobile
            type="image"
            src={back}
            onClick={closeModal}
          ></CloseStripeFormMobile>
          <SuccessModalTitle>Success!</SuccessModalTitle>
          <SuccessModalText>
            <p>
              You&apos;re a fantastic friend. Thanks for helping me achieve Dinero Dream Goal ever
              sooner
            </p>
          </SuccessModalText>
          {/* <section className="chart-container success-popup">
            <div className="reel">
              <div className="img-container">
                <img src={getProductImage()} />
              </div>
              <div>
                <ReactEcharts option={getOption()} style={{ height: 400 }} />
              </div>
              <div className="progress">
                <span className="number">
                  {progress} <small className="small">%</small>
                </span>
                <span className="saved-text">saved</span>
              </div>
            </div>
          </section> */}
          <div
            className={classNames('relative m-[1.4em] mb-[2em]', {
              'text-[33px] md:text-[60px]': variant === 'base',
              'text-[20px] md:text-[24px]': variant === 'sm',
            })}
          >
            <ProgressWheel isComplete={true} progress={progress} imageSrc={getProductImage()} />
            <div className="absolute left-1/2 bottom-0 flex -translate-x-1/2 translate-y-[1.8em] transform flex-col items-center justify-center gap-[0.2em]">
              <Typography variant="heading1" tag="span" className={styles['typography-override']}>
                <span
                  className={classNames([
                    styles['percentage-amount'],
                    'mr-[0.0666667em] leading-none',
                  ])}
                >
                  {Math.ceil(progress)}
                </span>
                <span className={classNames([styles['percentage-symbol'], 'leading-none'])}>%</span>
              </Typography>
              <Typography
                variant="label"
                className={classNames([styles['percentage-label'], styles['typography-override']])}
              >
                Saved
              </Typography>
            </div>
          </div>

          <a href={process.env.REACT_APP_SIGNUP_LINK + utmParams}>
            <ReelLink
              onClick={(event) => {
                window.dataLayer.push({
                  event: `app.event`,
                  eventCategory: 'chip-in',
                  eventType: 'Start your own Reel',
                  eventName: 'Start your own Reel',
                });
              }}
            >
              START MY OWN GOAL
            </ReelLink>
          </a>
        </SuccessModal>
      );
    }
  };

  return (
    <div className="btn-submit">
      <Button
        type="submit"
        onClick={(event) => {
          return openModal(event, props.value);
        }}
        disabled={props.amount <= 0 ? true : false}
      >
        CHIP IN
      </Button>

      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="Example Modal"
      >
        {modalContent()}
      </Modal>
    </div>
  );
};

export default withLDConsumer()(injectStripe(CCModal));

const ReferralContainer = styled.div`
  padding: 31px 23px 10px 26px;
  box-shadow: 0 2px 8px -1px rgb(202 202 202 / 50%);
  background-color: #f0e9e2;
  margin-top: 45px;
  position: relative;

  img {
    position: absolute;
    height: 50px;
    width: 50px;
    top: -21px;
    left: 50%;
    transform: translateX(-50%);
  }

  div {
    p {
      font-family: WorkSans;
      font-size: 1.2rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: var(--black);
    }

    span {
      font-family: BlackHanSans;
    }
  }
`;

const StyledButton = styled.button`
  border: none;
  font-family: WorkSans;
  height: 48px;
  display: block;
  cursor: pointer;
  margin: 20px auto 0 auto;
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  & :focus {
    outline: none;
  }
`;

const OpenModal = styled(StyledButton)`
  background-color: #dd5a57;
  font-size: 1.2rem;
  padding: 0 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.92px;
  text-align: center;
  color: #ffffff;

  // &.disabled {
  //   background: #b4b4b4;
  //   border: none;
  // }
`;

const DisableModal = styled(StyledButton)`
  background-color: #de7a78;
  padding: 0 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.92px;
  text-align: center;
  color: #ffffff;
  border: none;
`;

const StripeForm = styled.form`
  position: relative;
  padding: 42px;
  text-align: center;
  background: #f7f5f3;
  @media ${desktopDevice.tablet} {
    padding: 20px 20px;
  }
`;

const CloseStripeFormDesktop = styled.input`
  position: absolute;
  top: 30px;
  right: 30px;
  height: 18px;
  cursor: pointer;
  display: block;

  & :focus {
    outline: none;
  }

  @media ${desktopDevice.tablet} {
    display: none;
  }
`;

const CloseStripeFormMobile = styled.input`
  display: none;

  @media ${desktopDevice.tablet} {
    position: absolute;
    display: block;
    top: 8px;
    left: 20px;
    height: 18px;
    cursor: pointer;

    & :focus {
      outline: none;
    }
  }
`;

const ModalTitle = styled.h2`
  // font-family: proxima-nova;
  font-family: BlackHanSans;
  font-weight: 700;
  font-size: 2em;
  text-align: center;

  & span {
    display: inline-block;
    position: relative;
    z-index: 1;

    &::after {
      position: absolute;
      content: '';
      bottom: 5px;
      left: 0;
      width: 100%;
      height: 14px;
      background-color: #f39a7c;
      z-index: -1;
    }
  }

  @media only screen and (max-device-width: 767px) {
    margin: 10 auto;
    font-size: 28px;
  }

  @media ${device.mobileS} {
    margin: 10 auto;
    font-size: 28px;
  }
`;

const SuccessModalTitle = styled.h2`
  font-family: VanguardCF;
  font-size: 4em;
  text-align: center;
  /* font-family: HaboroContrast-NorDem; */
  /* font-size: 20px; */
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.2px;

  @media ${device.laptop} {
    margin: 0 auto;
    font-size: 4em;
  }
`;

const InputFrom = styled.div`
  display: grid;

  @media ${desktopDevice.tablet} {
    margin-top: 35px;
  }
`;

const ModalText = styled.div`
  margin: 30px 0;

  & p.about-you {
    text-align: center;
  }

  & p {
    font-family: WorkSans;
    font-size: 1.2rem;
    line-height: 1.5;
    letter-spacing: normal;
    color: black;
    text-align: left;
    width: 100%;

    @media only screen and (max-device-width: 767px) {
      display: none;
    }
  }

  @media only screen and (max-device-width: 767px) {
    margin: 0 auto;
  }

  @media ${device.mobileS} {
    margin: 0 auto;
  }
`;

const SuccessModalText = styled.div`
  & p {
    font-family: WorkSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    text-align: left;
    letter-spacing: normal;
    width: 100%;
  }
  @media ${device.laptop} {
    margin: 0 auto;
    font-size: 16px;
  }
`;

const ErrorText = styled.div`
  display: block;
  color: red;
  font-family: Gotham-book;
  font-size: 14px;
  visibility: ${(props) => (props.error === 'none' ? 'hidden' : 'visible')};
  margin: 10px auto;
`;

const NameWrapper = styled.div`
  background-color: transparent;
  display: grid;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  padding: 0px;
  & img {
    position: absolute;
    top: 15px;
    left: 13px;
    height: 14px;
  }

  & label {
    font-family: WorkSans;
    font-weight: 500;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.8px;
    color: black;
    text-align: left;
    margin-bottom: 6px;

    @media ${desktopDevice.tablet} {
      font-size: 12px;
    }
  }
`;

const NameInput = styled.input`
  padding: 15px;
  border: none;
  font-family: WorkSans;
  font-size: 16px;
  box-shadow: 0 2px 4px 0 rgb 0 0 0/6%;
  background-color: white;
  & :focus {
    outline: none;
  }

  @media ${desktopDevice.tablet} {
    font-size: 14px;
    padding: 12px;
  }
`;

const PhoneWrapper = styled.div`
  padding: 15px;
  border: none;
  font-family: WorkSans;
  font-size: 16px;
  box-shadow: 0 2px 4px 0 rgb 0 0 0/6%;
  background-color: white;
  & :focus {
    outline: none;
  }

  @media ${desktopDevice.tablet} {
    font-size: 14px;
    padding: 12px;
  }
`;

const PhoneInput = styled.input`
  padding-left: 0px;
  width: 100%;
  background-color: transparent;
  border: none;
  color: ${(props) => (props.valid === true ? '#0a0a0a' : '#cc0000')};
  font-size: 16px;
  font-family: WorkSans;
  opacity: 0.6;
  font-weight: normal;
  & :focus {
    outline: none;
  }
`;

const EmailWrapper = styled.div`
  background-color: transparent;
  height: 40px;
  width: 80%;
  max-width: 350px;
  display: flex;
  margin: 10px auto;
  position: relative;
  padding: 11px 0px;
  border-bottom: 1px solid #0a0a0a;
  flex-direction: column;

  & img {
    position: absolute;
    top: 15px;
    left: 12px;
    height: 13px;
  }

  & label {
    text-align: left;
    margin-bottom: 6px;
    font-family: WorkSans;
    font-weight: 600;
    font-size: 12px;
  }

  @media ${device.mobileS} {
    margin: 5px auto;
  }
`;

const EmailInput = styled.input`
  padding-left: 0px;
  width: 100%;
  background-color: transparent;
  border: none;
  color: ${(props) => (props.valid === true ? '#0a0a0a' : '#cc0000')};
  font-size: 16px;
  font-family: WorkSans;
  opacity: 0.6;
  font-weight: normal;
  & :focus {
    outline: none;
  }
`;

const AmountInfo = styled.span`
  display: block;
  margin: 0px auto;
  width: 100%;
  margin-top: 20px;
  text-align: left;
   font-family: WorkSans;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: black;

    @media ${desktopDevice.tablet} {
      font-size: 14px;
    }


    span {
      font-family: WorkSans;
      font-weight: 500;
      letter-spacing: normal;
      text-align: center;
      margin: 15px 0;
      line-height: 20px;
      font-size: 16px;
      color: black;

    @media ${desktopDevice.tablet} {
      font-size: 14px;
    }
    }
}


`;

const AmountAgreement = styled.div`
  display: flex;
  align-items: start;
  line-height: 20px;
  justify-content: center;
  width: 100%;
  position: relative;
  right: 0px;
  margin: 20px auto 0px auto;
  text-align: left;
  align-items: flex-start;
`;

const EmailAgreement = styled.div`
  display: flex;
  align-items: start;
  line-height: 20px;
  justify-content: center;
  width: 100%;
  position: relative;
  right: 0px;
  margin: 20px auto 0px auto;
  text-align: left;
  align-items: flex-start;
`;

const Required = styled.span`
  visibility: ${(props) => (props.agreementError ? 'visible' : 'hidden')};
  color: ${(props) => (props.agreementError ? 'red' : '#cc000')};
  position: relative;
  top: 2px;
  left: 3px;
`;

const Checkbox = styled.input`
  display: none;
  cursor: pointer;
`;

const RoundCheckbox = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  right: 0;
  top: 0px;
  flex: 1;

  & img {
    height: 20px;
    filter: brightness(0%);
  }
`;

const SpanText = styled.span`
  font-family: WorkSans;
  color: ${(props) => (props.agreementError ? 'red' : '#000')};
  flex: 12;
  margin-left: 10px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;

  @media ${desktopDevice.tablet} {
    font-size: 14px;
  }

  & a {
    color: #dd5a57;
  }
`;

const EmailText = styled.span`
  font-family: WorkSans;
  color: #000;
  flex: 12;
  margin-left: 10px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;

  @media ${desktopDevice.tablet} {
    font-size: 14px;
  }

  & a {
    color: #dd5a57;
  }

  @media ${device.mobileS} {
    font-size: 10px;
    line-height: 14px;
  }
`;

const SubmitStripe = styled(StyledButton)`
  width: 100%;
  background-color: #dd5a57;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.92px;
  text-align: center;
  color: #ffffff;
`;

const SubmitText = styled.div`
  font-size: 1.2rem;
  display: ${(props) => (props.processing ? 'none' : 'block')};
`;

const SuccessModal = styled.div`
  position: relative;
  padding: 25px;
  text-align: center;
  height: 100vh;
  // min-height: 482px;

  @media ${device.tablet} {
    padding: 25px;
    height: auto;
  }

  & a {
    text-decoration: none;
  }
`;

const ModalImage = styled.img`
  max-width: 75%;
  margin: 50px auto;
  max-height: 175px;
`;

const ReelLink = styled(StyledButton)`
  font-family: WorkSans, serif !important;
  font-weight: 500 !important;
  width: 100%;
  text-decoration: none;
  letter-spacing: 0.69px;
  border-color: #dd5a57;
  color: #fff;
  background-color: #dd5a57;
`;

const SecureTag = styled.div`
    margin-top: 5px;
    & img {
        height: 11px;
        position: relative;
        top: 1px;
        right: 5px;
    }
    & span {
        font-size 10px;
        line-height: 10px;
        font-family: proxima-nova;
    }
`;
