import React, { useState, useContext, useEffect } from 'react';
import device from '../Device';
import desktopDevice from '../DesktopFirstDevice';

import { Elements } from 'react-stripe-elements';
import styled from 'styled-components/macro';
import { addDays } from 'date-fns';
import unselectedIcon from '../assets/icons/Unselected.svg';
import selectedIcon from '../assets/icons/Selected.svg';
import messageIcon from '../assets/icons/Message-Outline.svg';
import CCModal from './CardInformationForm';
import { PaymentContext } from '../context/PaymentContext';
import { ProgressContext } from '../context/ProgressContext';
import { IMaskInput } from 'react-imask';
import { useRef } from 'react';
const fonts = [{ cssSrc: 'https://use.typekit.net/zel0tkp.css?' }];

const PaymentForm = ({
  userName,
  itemPrice,
  goalOwner,
  userGoal,
  userId,
  goalId,
  goalUpdated,
  chipInTotal,
  amountRemainingToBePaid,
  transactionsTotal,
}) => {
  // use ref to get access to internal "masked = ref.current.maskRef"
  const ref = useRef(null);
  const inputRef = useRef(null);

  let { payment, dispatch } = useContext(PaymentContext);
  let { progress } = useContext(ProgressContext);
  const [amountWithoutChipInPercentage, setAmountWithoutChipInPercentage] = useState(0);

  useEffect(() => {
    setAmountWithoutChipInPercentage(((transactionsTotal - chipInTotal) / itemPrice) * 100);
  }, [itemPrice, transactionsTotal, chipInTotal]);

  let MONTH_NAMES = [
    {
      long: 'January',
      short: 'Jan',
    },
    {
      long: 'February',
      short: 'Feb',
    },
    {
      long: 'March',
      short: 'Mar',
    },
    {
      long: 'April',
      short: 'Apr',
    },
    {
      long: 'May',
      short: 'May',
    },
    {
      long: 'June',
      short: 'Jun',
    },
    {
      long: 'July',
      short: 'Jul',
    },
    {
      long: 'August',
      short: 'Aug',
    },
    {
      long: 'September',
      short: 'Sep',
    },
    {
      long: 'October',
      short: 'Oct',
    },
    {
      long: 'November',
      short: 'Nov',
    },
    {
      long: 'December',
      short: 'Dec',
    },
  ];

  let [message, saveMessage] = useState('');
  const [amount, setAmount] = useState(0);

  function getFullBalance() {
    let balance = itemPrice - progress.userProgress.paid;
    return Math.round(balance / 100);
  }

  function formatMoney(n) {
    return (Math.round(n * 100) / 100).toLocaleString();
  }

  function getStrategyDurationInDays(goalAmount, strategy) {
    const strategyAmount = strategy.installmentAmount;
    return Math.ceil((goalAmount / strategyAmount) * strategy.installmentDurationInDays);
  }

  function getFinalGoalPrice(goal) {
    let amount = 0;
    if (goal && goal.target) {
      if (goal.target.amount && goal.target.amount.amount) {
        amount += goal.target.amount.amount;
      }
      if (goal.target.shipping) {
        amount += goal.target.shipping.amount;
      }
      if (goal.target.tax) {
        amount += goal.target.tax.amount;
      }
      if (goal.target.discount) {
        amount -= goal.target.discount.amount;
      }
    }

    return amount;
  }

  function getStrategyEndDateMsg(reel, useCharacter = true, strategy = null) {
    if (reel) {
      const amountRemaining = getFinalGoalPrice(reel) - progress.userProgress.paid;
      const daysUntilCompletion = getStrategyDurationInDays(amountRemaining, reel.strategy);
      const daysUntilArrival = daysUntilCompletion + 10;
      const dateToday = new Date();
      const date = addDays(dateToday, daysUntilArrival);

      let getYear = getDisplayYear(date);
      let dateMsg = `${getDisplayMonth(date)} ${date.getDate()}`;
      if (useCharacter) {
        return `${dateMsg} ${getYear ? ', ' + getYear : '!'}`;
      }
      return `${dateMsg} ${getYear ? ', ' + getYear : ''}`;
    }
  }

  function capitalize(s) {
    if (s) {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    } else {
      return '';
    }
  }

  function getDisplayYear(date) {
    let currentDate = new Date();
    return currentDate.getFullYear() != date.getFullYear() ? `${date.getFullYear()}` : null;
  }

  function getDisplayMonth(date) {
    return `${MONTH_NAMES[date.getMonth()] ? MONTH_NAMES[date.getMonth()].short : ''}`;
  }

  function getProductName() {
    if (userGoal) {
      return `${userGoal.product.name}`;
    } else {
      return '';
    }
  }

  useEffect(() => {
    dispatch({ type: 'UPDATE_FULL_AMOUNT', fullAmount: getFullBalance() });
  }, [payment.fullBalance]);

  let minusOne = (e) => {
    e.preventDefault();
    dispatch({ type: 'UPDATE_FULL_BALANCE', boolean: false });
    if (payment.amount <= 5) {
      dispatch({ type: 'UPDATE_AMOUNT', amount: 5 });
    } else
      dispatch({
        type: 'UPDATE_AMOUNT',
        amount: payment.amount !== 0 ? payment.amount - 1 : 0,
      });
  };

  const convertCurrencyStringToCents = (currencyStr) => {
    if (!currencyStr) {
      return 0;
    }

    return Math.round(+currencyStr.replace(',', '').replace('$', '') * 100);
  };

  const updatePayment = (value) => {
    dispatch({
      type: 'UPDATE_FULL_BALANCE',
      boolean: false,
    });
    const remainingBalance = getFullBalance();
    if (value <= remainingBalance) {
      dispatch({
        type: 'UPDATE_AMOUNT',
        amount: value,
      });
    } else {
      dispatch({
        type: 'UPDATE_AMOUNT',
        amount: remainingBalance,
      });
    }
  };

  const giftMessage = (event) => {
    saveMessage((message = event.target.value));
  };

  return (
    <FormContainer autoComplete="false" className="form-wrapper sm:my-4">
      <p className="desktaop-view-box">
        {capitalize(goalOwner)} is saving up for{' '}
        <span className="product-name">{getProductName()}</span>. Be a supportive friend by chipping
        in. While you’re at it invite more friends and fam!
        {/* {capitalize(goalOwner)} is saving up to buy a{' '}
        <span className="product-name">{getProductName()}</span>. Be a good friend by chipping in
        towards what {capitalize(userName)} really wants. */}
      </p>

      <SpanText className="want-mobile">I WANT TO CHIP IN</SpanText>
      <div className="input-container">
        <AmountContainer className="slider-wrapper">
          {/* <IMaskInput
            name="currMask"
            value={payment.amount === 0 ? '' : `$${payment.amount.toFixed(2)}`}
            onChange={(value) => {
              updatePayment(convertCurrencyStringToCents(value) / 100);
            }}
            // onChange={(value) => {
            //   const amountInCents = convertCurrencyStringToCents(value) / 100;
            //   if (amountInCents <= amountRemainingToBePaid / 100) {
            //     updatePayment(amountInCents);
            //   }
            // }}
            {...{
              mask: '$XX',
              blocks: {
                XX: {
                  mask: Number,
                  thousandsSeparator: ',',
                  radix: '.',
                  scale: true ? 2 : 0,
                  normalizeZeros: true,
                  padFractionalZeros: true,
                },
              },
            }}
            className="currency-input"
            placeholder="$0.00"
          /> */}
          <IMaskInput
            name="currMask"
            value={payment.amount === 0 ? '' : `$${payment.amount.toFixed(2)}`}
            {...{
              mask: '$XX',
              blocks: {
                XX: {
                  mask: Number,
                  thousandsSeparator: ',',
                  radix: '.',
                  scale: true ? 2 : 0,
                  normalizeZeros: true,
                  padFractionalZeros: true,
                },
              },
            }}
            ref={ref}
            inputRef={inputRef} // access to nested input
            // DO NOT USE onChange TO HANDLE CHANGES!
            // USE onAccept INSTEAD
            onAccept={
              // depending on prop above first argument is
              // `value` if `unmask=false`,
              // `unmaskedValue` if `unmask=true`,
              // `typedValue` if `unmask='typed'`
              (value, mask) => {
                updatePayment(convertCurrencyStringToCents(value) / 100);
              }
            }
            className="currency-input"
            placeholder="$0.00"
          />
          {/* <IMaskInput
            className="currency-input"
            {...{
              mask: '$XX',
              blocks: {
                XX: {
                  mask: Number,
                  thousandsSeparator: ',',
                  radix: '.',
                  scale: true ? 2 : 0,
                  normalizeZeros: true,
                  padFractionalZeros: true,
                },
              },
            }}
            value={payment.amount === 0 ? '' : payment.amount.toString()}
            onAccept={(value, mask) => {
              //  setAmount(convertCurrencyStringToCents(value) / 100);
              updatePayment(convertCurrencyStringToCents(value) / 100);
              mask.updateValue(); // Add this line to synchronize the mask value
              //console.log(convertCurrencyStringToCents(value));
            }}
            placeholder="$0.00"
          /> */}
        </AmountContainer>
        <TextContainer className="radio-button">
          <Checkbox id="fullBalance" type="checkbox"></Checkbox>
          <RoundCheckbox
            htmlFor="fullBalance"
            onClick={() => {
              if (!payment.fullBalance) {
                dispatch({
                  type: 'UPDATE_FULL_BALANCE',
                  boolean: !payment.fullBalance,
                });
                dispatch({
                  type: 'UPDATE_AMOUNT',
                  amount: amountRemainingToBePaid / 100,
                });
              } else {
                dispatch({
                  type: 'UPDATE_FULL_BALANCE',
                  boolean: !payment.fullBalance,
                });
                dispatch({
                  type: 'UPDATE_AMOUNT',
                  amount: 0,
                });
              }
            }}
          >
            <img
              src={!payment.fullBalance ? unselectedIcon : selectedIcon}
              alt="Full Balance Toggle Icon"
            />
          </RoundCheckbox>
          <LabelText
            className="radio-btn"
            htmlFor="fullBalance"
            onClick={() => {
              dispatch({
                type: 'UPDATE_FULL_BALANCE',
                boolean: !payment.fullBalance,
              });
            }}
          >
            I want to chip in the full balance:{' '}
            <span>${formatMoney(amountRemainingToBePaid / 100)}</span>
          </LabelText>
        </TextContainer>
      </div>
      <MessageWrapper className="message-wrapper">
        {/* <img src={messageIcon} alt="Message Icon"></img> */}
        <SpanText className="want-mobile">
          LEAVE A MESSAGE FOR {userName ? userName.toUpperCase() : ''}
        </SpanText>
        <MessageInput
          className="w-full"
          // className="textarea-box"
          name="gift-message"
          rows="5"
          cols="30"
          maxLength="200"
          placeholder={`Type here`}
          onChange={(event) => giftMessage(event)}
        ></MessageInput>
      </MessageWrapper>
      <Elements fonts={fonts}>
        <CCModal
          value={[payment.fullBalance ? payment.fullAmount : payment.amount, message, goalOwner]}
          doneBy={getStrategyEndDateMsg(userGoal, false)}
          userGoal={userGoal}
          chipInSuccess={goalUpdated}
          progress={progress}
          userId={userId}
          amountWithoutChipInPercentage={amountWithoutChipInPercentage}
          goalId={goalId}
          name={userName}
          amount={payment.amount}
        />
      </Elements>
    </FormContainer>
  );
};

export default PaymentForm;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  width: 100%;

  @media ${desktopDevice.tablet} {
    margin-top: 0px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const SpanText = styled.span`
  font-stretch: normal;
  font-style: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  text-align: center;
  font-family: WorkSans;
  font-weight: 500;
  margin: 15px 0;
  font-size: 16px;
  color: black;
  line-height: 1.33;
  letter-spacing: 0.8px;

  @media ${desktopDevice.tablet} {
    font-size: 12px;
    margin: 0px;
  }
`;

const LabelText = styled.label`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  font-family: WorkSans;
  margin: 15px 0;
  line-height: 20px;
  cursor: pointer;
  text-align: left;

  @media ${desktopDevice.tablet} {
    font-size: 14px;
  }


  span {
    font-stretch: normal;
    font-style: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    text-align: center;
    font-family: WorkSans;
    font-weight: 500;
    margin: 15px 0;
    line-height: 20px;
    font-size: 16px;
    color: black;
    @media ${desktopDevice.tablet} {
      font-size: 14px;
    }
  
}
  }
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100% !important;
`;

const Amount = styled.span`
  font-size: 42px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: center;
  color: #000000;
  width: auto;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  font-family: 'HaboroContrast-NorBoo';
`;

const AmountInput = styled.input.attrs(() => ({
  type: 'number',
}))`
  font-family: Gotham-Book;
  font-size: 1.3em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: center;
  color: #000000;
  width: 80px;
  text-align: center;
  border: none;
  padding: 0;

  & :focus {
    outline: none;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  & ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

const IncrementButton = styled.input.attrs(() => ({
  type: 'image',
}))`
  margin: 0 15px;
  height: 35px;
  transition: transform 0.5s linear;
  cursor: pointer;

  & :focus {
    outline: none;
  }

  & :hover {
    transform: scale(1.1);
  }

  & :active {
    transform: scale(1);
  }
`;
const Checkbox = styled.input`
  display: none;
  cursor: pointer;
`;

const RoundCheckbox = styled.label`
  position: relative;
  right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  & img {
    height: 15px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 20px;
`;

const MessageWrapper = styled.div`
  background-color: transparent;
  height: auto;
  width: 100%;

  display: block;
  position: relative;
  padding-left: 0;

  & img {
    position: absolute;
    top: 15px;
    left: 13px;
    height: 14px;
  }

  @media only screen and (max-width: 768px) {
    max-width: inherit;
  }
`;

const MessageInput = styled.textarea`
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  width: 97%;
  border: none;
  height: 100px;
  font-family: WorkSans;
  font-size: 20px;
  box-shadow: 0 2px 4px 0 rgb (0 0 0 / 6%);
  background-color: white;

  @media ${desktopDevice.tablet} {
    margin-top: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    font-size: 15px;
  }

  & ::placeholder {
    color: #707070;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: -0.38px;
    font-family: WorkSans;
  }

  & :focus {
    outline: none;
  }
`;
