import React from 'react';
import cn from 'classnames';
import styles from './LoadingSpinner.module.css';

export interface LoadingSpinnerProps {
  size?: 'sm' | 'md' | 'lg';
}

export const LoadingSpinner = ({ size = 'md' }: LoadingSpinnerProps) => (
  <div className="inline-grid grid-flow-col gap-0.5">
    {[1, 2, 3].map((key) => (
      <div
        key={key}
        className={cn('inline-block rounded-full bg-current', styles.ball, {
          'h-2 w-2': size === 'sm',
          'h-4 w-4': size === 'md',
          'h-8 w-8': size === 'lg',
        })}
      ></div>
    ))}
  </div>
);
