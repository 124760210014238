// Footer URLs
export const WHAT_WE_DO_URL = 'https://sumawealth.com/what-we-do';
export const RENT_OR_HOMEOWNER_URL = 'https://sumawealth.com/tools/home-rent-vs-buy';
export const GROW_YOUR_SAVINGS_URL = 'https://sumawealth.com/tools/savings';
export const PAY_YOUR_CREDIT_CARD_URL = 'https://sumawealth.com/tools/credit-card-payoff';
export const FREELANCE_OR_SELF_EMPLOYED_URL =
  'https://sumawealth.com/tools/incorporate-vs-freelance';
export const WHAT_IS_SUMA_URL = 'https://sumawealth.com/what-is-suma';
export const TERMS_AND_CONDITIONS_URL = 'https://sumawealth.com/terms';
export const PRIVACY_POLICY_URL = 'https://sumawealth.com/privacy-policy';

export const THEME_COLORS = {
  black: '#000000',
  berry: '#501639',
  gray: {
    'ultra-light': '#f0f0f0',
    light: '#d0d0d0',
    DEFAULT: '#b4b4b4',
    dark: '#979797',
  },
  apricot: {
    DEFAULT: '#de5a56',
    dark: '#b3674c',
  },
  orange: '#ec7951',
  albescent: '#f6e8d8',
  cacao: '#312525',
  suma: '#bb3126',
  'suma-blue': '#4fb1ca',
  'suma-blue-dark': '#27314A',
  'suma-green': '#a5c993',
  'suma-white': '#fdfdfd',
  'text-suma-red': '#de5a56',
  main: '#EBF3F8',
  cta: '#DD5A57',
  malt: '#e5dfd8',
  cream: '#eee8e2',
  alabaster: '#f0e9e2',
  white: '#ffffff',
  pampas: '#f6f5f3',
  success: '#c9dfb6',
  mustard: '#dfa821',
  error: '#d6564f',
  warn: '#ffc022',
  highlight: '#f39b7d',
  'random-green-1': '#84ac60',
  'random-green-2': '#eff4e9',
  'random-gray-1': '#fdfcfa',
  'random-gray-2': '#efe8e2',
  'random-gray-3': '#f6f2ee',
  'random-gray-4': '#b9b3ac',
  'random-tan-1': '#f9e8d6',
  'random-tan-2': '#fef0e2',
  'random-tan-3': '#f4efea',
  'random-apricot-1': '#fff6ed',
  'random-apricot-2': '#f3a87c',
  'random-red-1': '#ffebe6',
};

export const BENJI_IMAGE_URL = '/images/Savings-Summary-Icon.svg';
