import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Goal from './components/Goal';
import desktopDevice from './DesktopFirstDevice';
// import { useAuth0 } from "./react-auth0-spa";
import PaymentContextProvider from './context/PaymentContext';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import ProgressContextProvider from './context/ProgressContext';
import classNames from 'classnames';
import Footer from './components/organisms/Footer';
import AppTemplate from './components/templates/AppTemplate';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const script = document.createElement('script');
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','${process.env.REACT_APP_GA_TRACKING_CODE}');`;
  script.async = true;
  document.head.appendChild(script);

  window.dataLayer.push({
    event: `app.event`,
    eventCategory: 'chip-in',
    eventType: 'open',
    eventName: 'open',
  });

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <AppTemplate>
        <HelmetProvider>
          <PaymentContextProvider>
            <ProgressContextProvider>
              <Goal />
            </ProgressContextProvider>
          </PaymentContextProvider>
        </HelmetProvider>
      </AppTemplate>
    </>
  );
}

export default withLDProvider({
  clientSideID: `${process.env.REACT_APP_LD_CLIENT}`,
  user: {
    key: new Date().getMilliseconds(),
    anonymous: true,
  },
})(App);
