import React from 'react';
import {
  RENT_OR_HOMEOWNER_URL,
  FREELANCE_OR_SELF_EMPLOYED_URL,
  PRIVACY_POLICY_URL,
  PAY_YOUR_CREDIT_CARD_URL,
  GROW_YOUR_SAVINGS_URL,
  TERMS_AND_CONDITIONS_URL,
  WHAT_IS_SUMA_URL,
} from '../../../utils/constants';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '../../atoms/Typography';
import VerticalLayout from '../../layouts/VerticalLayout';
// import { faCopyright } from '@fortawesome/fontawesome-svg-core/index';
import { getYear } from 'date-fns';
import logo from '../../../assets/images/logo-suma.svg';

export const Footer = () => {
  return (
    <footer className="min-w-full bg-suma py-12 text-cream bg-img-dollar-footer">
      <div className="container">
        <section className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4 md:max-w-1xl">
          <VerticalLayout>
            <div className="flex justify-between items-end w-full">
              <div className="relative w-[65px] h-[32px] md:w-[98px] md:h-[48px]">
                <img className="content-center text-center" alt="Suma Wealth Logo" src={logo} />
              </div>
            </div>
          </VerticalLayout>

          <VerticalLayout>
            <a href="/what-we-do" target="_blank" rel="noreferrer">
              <h3 className="font-heading text-xl md:text-2xl font-bold">What we do</h3>
            </a>
          </VerticalLayout>

          <VerticalLayout>
            <h3 className="font-heading text-xl md:text-2xl font-bold">Dinero Toolkit</h3>
            <a href={RENT_OR_HOMEOWNER_URL} target="_blank" rel="noreferrer">
              <Typography variant="body">Rent or Homeowner?</Typography>
            </a>
            <a href={GROW_YOUR_SAVINGS_URL} target="_blank" rel="noreferrer">
              <Typography variant="body">Grow your savings</Typography>
            </a>
            <a href={PAY_YOUR_CREDIT_CARD_URL} target="_blank" rel="noreferrer">
              <Typography variant="body">Pay Your Credit Card</Typography>
            </a>
            <a href={FREELANCE_OR_SELF_EMPLOYED_URL} target="_blank" rel="noreferrer">
              <Typography variant="body">Freelance or Self employed</Typography>
            </a>
          </VerticalLayout>

          <VerticalLayout>
            <h3 className="font-heading text-xl md:text-2xl font-bold">About suma</h3>
            <a href={WHAT_IS_SUMA_URL} target="_blank" rel="noreferrer">
              <Typography variant="body">What is Suma</Typography>
            </a>
          </VerticalLayout>
        </section>

        <section className="max-w-4xl mt-10">
          <div className="flex flex-row gap-3 sm:justify-items-center">
            <Typography variant="body">
              {/* <FontAwesomeIcon icon="fa-thin fa-copyright" /> */}
              {/* <FontAwesomeIcon icon={faCopyright} /> {getYear(new Date())} Suma Inc. */}Ⓒ{' '}
              {getYear(new Date())} Suma Inc.
            </Typography>

            <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
              <Typography variant="body">Privacy</Typography>
            </a>

            <a href={TERMS_AND_CONDITIONS_URL} target="_blank" rel="noreferrer">
              <Typography variant="body">Terms</Typography>
            </a>
          </div>
        </section>
      </div>
    </footer>
  );
};
