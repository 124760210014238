import React, { ElementType } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import LoadingSpinner from '../LoadingSpinner';
import cn, { Argument } from 'classnames';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'blue'
  | 'blue-dark'
  | 'transparent'
  | 'confirm'
  | 'warning'
  | 'link'
  | 'link-secondary'
  | 'qa';

export interface ButtonProps extends React.ComponentProps<'button'> {
  children: React.ReactNode;
  variant?: ButtonVariant;
  size?: 'base' | 'sm';
  disabled?: boolean;
  loading?: boolean;
  icon?: IconProp;
  fullWidth?: boolean;
  iconComponent?: React.ReactNode;
  as?: ElementType;
}

type VariantConfig = {
  classNames: Argument[];
};

export const Button = ({
  children,
  disabled,
  loading,
  variant = 'primary',
  fullWidth = true,
  icon,
  size = 'base',
  className,
  iconComponent,
  as = 'button',
  ...buttonProps
}: ButtonProps) => {
  const isButtonEnabled = !disabled && !loading;

  const variantConfig: Record<ButtonVariant, VariantConfig> = {
    primary: {
      classNames: [
        'border border-solid text-white tracking-wider rounded active:bg-opacity-90 active:scale-[98%] hover:bg-opacity-95 uppercase',
        isButtonEnabled ? 'bg-cta' : 'border-gray-dark bg-gray-dark text-white',
        {
          'text-xs md:text-sm px-8 h-[46px] md:h-[48px]': size === 'base',
          'text-xxs md:text-xs px-4 h-[24px] md:h-[30px]': size === 'sm',
          'w-full': fullWidth,
        },
      ],
    },
    secondary: {
      classNames: [
        'border border-solid bg-pampas tracking-wider uppercase font-medium',
        isButtonEnabled ? 'text-cacao border-cacao' : 'text-gray-dark border-gray-dark',
        {
          'text-xs md:text-sm px-8 h-[46px] md:h-[60px]': size === 'base',
          'text-xxs md:text-xs px-4 h-[24px] md:h-[36px]': size === 'sm',
          'w-full': fullWidth,
        },
      ],
    },
    tertiary: {
      classNames: [
        'border border-solid text-white tracking-wider active:bg-opacity-90 active:scale-[98%] hover:bg-opacity-95 uppercase',
        isButtonEnabled ? 'bg-[#263247]' : 'border-gray-dark bg-gray-dark text-white',
        {
          'text-xs md:text-sm px-8 h-[46px] md:h-[60px]': size === 'base',
          'text-xxs md:text-xs px-4 h-[24px] md:h-[36px]': size === 'sm',
          'w-full': fullWidth,
        },
      ],
    },
    blue: {
      classNames: [
        'border border-solid text-white tracking-wider rounded active:bg-opacity-90 active:scale-[98%] hover:bg-opacity-95 uppercase',
        isButtonEnabled ? 'bg-suma-blue' : 'border-gray-dark bg-gray-dark text-white',
        {
          'text-xs md:text-sm px-8 h-[46px] md:h-[48px]': size === 'base',
          'text-xxs md:text-xs px-4 h-[24px] md:h-[30px]': size === 'sm',
          'w-full': fullWidth,
        },
      ],
    },
    'blue-dark': {
      classNames: [
        'border border-solid text-white tracking-wider rounded active:bg-opacity-90 active:scale-[98%] hover:bg-opacity-95 uppercase',
        isButtonEnabled ? 'bg-suma-blue-dark' : 'border-gray-dark bg-gray-dark text-white',
        {
          'text-xs md:text-sm px-8 h-[46px] md:h-[48px]': size === 'base',
          'text-xxs md:text-xs px-4 h-[24px] md:h-[30px]': size === 'sm',
          'w-full': fullWidth,
        },
      ],
    },
    transparent: {
      classNames: [
        'border border-solid border-2 rounded tracking-wider uppercase font-medium',
        isButtonEnabled ? 'text-cacao border-suma-blue-dark' : 'text-gray-dark border-gray-dark',
        {
          'text-xs md:text-sm px-8 h-[46px] md:h-[48px]': size === 'base',
          'text-xxs md:text-xs px-4 h-[24px] md:h-[30px]': size === 'sm',
          'w-full': fullWidth,
        },
      ],
    },
    confirm: {
      classNames: [
        'text-white tracking-wider active:bg-opacity-90 active:scale-[98%] hover:bg-opacity-95 uppercase',
        isButtonEnabled ? 'bg-green-600' : 'bg-gray-dark text-white',
        {
          'text-xs md:text-sm px-8 h-[46px] md:h-[60px]': size === 'base',
          'text-xxs md:text-xs px-4 h-[24px] md:h-[36px]': size === 'sm',
          'w-full': fullWidth,
        },
      ],
    },
    warning: {
      classNames: [
        'text-white tracking-wider active:bg-opacity-90 active:scale-[98%] hover:bg-opacity-95 uppercase',
        isButtonEnabled ? 'bg-error' : 'bg-gray-dark text-white',
        {
          'text-xs md:text-sm px-8 h-[46px] md:h-[60px]': size === 'base',
          'text-xxs md:text-xs px-4 h-[24px] md:h-[36px]': size === 'sm',
          'w-full': fullWidth,
        },
      ],
    },
    // For non-production use only
    qa: {
      classNames: [
        'text-white tracking-wider active:bg-opacity-90 active:scale-[98%] hover:bg-opacity-95 uppercase',
        isButtonEnabled ? 'bg-gradient-to-br from-berry to-apricot' : 'bg-gray-dark text-white',
        {
          'text-xs md:text-sm px-8 h-[46px] md:h-[60px]': size === 'base',
          'text-xxs md:text-xs px-4 h-[24px] md:h-[36px]': size === 'sm',
          'w-full': fullWidth,
        },
      ],
    },
    link: {
      classNames: [
        'bg-transparent w-min underline-current uppercase tracking-widest underline font-medium',
        isButtonEnabled ? 'text-apricot' : 'text-gray-dark',
        {
          'text-xs md:text-sm': size === 'base',
          'text-xxs md:text-xs': size === 'sm',
        },
      ],
    },
    'link-secondary': {
      classNames: [
        'bg-transparent w-min underline text-xs md:text-sm',
        isButtonEnabled ? 'text-cacao' : 'text-gray-dark',
        size === 'base' ? 'text-xs md:text-sm' : 'text-xxs md:text-xs',
      ],
    },
  };

  const { classNames } = variantConfig[variant];

  const C = as;
  return (
    <C
      className={cn(
        'relative select-none transition flex-shrink-0 flex items-center justify-center',
        classNames,
        disabled && 'cursor-not-allowed',
        loading && 'cursor-wait',
        className
      )}
      type="button"
      disabled={!isButtonEnabled}
      {...buttonProps}
    >
      {icon && !iconComponent && !loading && (
        <div className="fa-lg pr-3">
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      {iconComponent && !loading && (
        <div className="fa-lg pr-3">
          {iconComponent}
        </div>
      )}
      <span className="inline-grid auto-cols-max grid-flow-col items-center gap-4">
        {loading && <LoadingSpinner size="sm" />}
        {children}
        {/* Keeps the text centered */}
        {loading && (
          <span className="inline-grid h-0 opacity-0">
            <LoadingSpinner size="sm" />
          </span>
        )}
      </span>
    </C>
  );
};
