import Footer from '../../organisms/Footer';
import { AppHeader } from '../../organisms/Header/Header';
import React from 'react';
import classNames from 'classnames';

interface AppTemplateProps {
  showHeader?: boolean;
  showFooter?: boolean;
  children: React.ReactNode;
  className?: string;
  containerFluid?: boolean;
  customPadding?: string;
}

export const AppTemplate: React.FC<AppTemplateProps> = (props) => {
  return (
    <>
      <div id="app-template-top-escape-hatch" />
      <AppTemplateContents {...props} />
      <div id="app-template-bottom-escape-hatch" />
    </>
  );
};

const AppTemplateContents: React.FC<AppTemplateProps> = ({
  showHeader = true,
  showFooter = true,
  containerFluid = false,
  customPadding = 'py-9 md:py-16',
  className,
  children,
}) => (
  <>
    <div className="flex min-h-screen flex-col">
      <div className="flex-shrink-0">{showHeader && <AppHeader />}</div>

      <main
        className={classNames(
          'flex h-full flex-grow  break-words',
          containerFluid ? 'container-fluid' : 'container',
          customPadding,
          className
        )}
      >
        {children}
      </main>

      {/* Can be used by portals to display content outside the limits of the app
      template contents and above the footer */}
      <div id="app-template-contents-bottom-escape-hatch" />
    </div>
    {showFooter && <Footer></Footer>}
  </>
);
