import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Progress from './Progress';
import PaymentForm from './PaymentForm';
import { PaymentContext } from '../context/PaymentContext';
import { ProgressContext } from '../context/ProgressContext';
import './Progress.scss';
import VerticalLayout from './layouts/VerticalLayout/VerticalLayout';
import Typography from './atoms/Typography';
import notFoundImg from '../assets/images/404-error.png';
import Button from './atoms/Button';
import { Helmet } from 'react-helmet-async';
import LoadingSpinner from './atoms/LoadingSpinner';

const Goal = () => {
  let uri = window.location.href;

  const params = new URLSearchParams(uri);
  const firstName = params.get('name');
  const userId = params.get('user');
  const goalId = params.get('goal');

  let { payment } = useContext(PaymentContext);
  let { progress } = useContext(ProgressContext);

  const [loading, setLoading] = useState(false);
  const [userGoal, setUserGoal] = useState();
  const [transactionsTotal, setTransactionsTotal] = useState();
  const [chipInTotal, setChipInTotal] = useState();
  const [goalStatus, setGoalStatus] = useState('');
  const [goalExists, setGoalExists] = useState(true);
  const [canChipIn, setCanChipIn] = useState(true);
  const [amountRemainingToBePaid, setAmountRemainingToBePaid] = useState(0);

  let itemPrice = 0;
  if (userGoal && userGoal.target) {
    if (userGoal.target.amount && userGoal.target.amount.amount) {
      itemPrice += userGoal.target.amount.amount;
    }
    if (userGoal.target.shipping) {
      itemPrice += userGoal.target.shipping.amount;
    }
    if (userGoal.target.tax) {
      itemPrice += userGoal.target.tax.amount;
    }
    if (userGoal.target.discount) {
      itemPrice -= userGoal.target.discount.amount;
    }
  }

  let goalOwner = firstName !== 'name' && firstName !== '' ? firstName : 'Friend';
  const utmParams =
    '?utm_source=chip%20in&utm_campaign=awareness&utm_content=chipin_modal_startmyownreel_button';

  useEffect(() => {
    setLoading(true);

    getGoal();
  }, []);

  async function getGoal() {
    const result = await axios.post(`${process.env.REACT_APP_API}`, {
      data: {
        userId: userId,
        goalId: goalId,
      },
    });

    if (result.data.body.message === 'Goal exists') {
      setLoading(false);
      setUserGoal(JSON.parse(result.data.body.goalStatus));
      setTransactionsTotal(JSON.parse(result.data.body.transactionsTotal));
      setChipInTotal(JSON.parse(result.data.body.chipInTotal));
      setGoalStatus(result.data.body.message);
      setGoalExists(true);

      let goal = JSON.parse(result.data.body.goalStatus);

      let paid = JSON.parse(result.data.body.transactionsTotal);

      const discount =
        goal.target.discount && goal.target.discount.amount ? goal.target.discount.amount : 0;

      const shipping =
        goal.target.shipping && goal.target.shipping.amount ? goal.target.shipping.amount : 0;

      let itemPrice = goal.target.amount.amount + goal.target.tax.amount + shipping - discount;

      setAmountRemainingToBePaid(itemPrice - paid);

      let percentage = Math.floor((paid / itemPrice) * 100);

      if (percentage >= 100 || itemPrice - paid < 500) {
        setCanChipIn(false);
      }
    } else {
      setLoading(false);
      console.log(result.data.body.message);
      setGoalExists(false);
    }
  }

  function goalContent() {
    return goalExists ? (
      <>
        <div className="w-full gap-3 lg:grid lg:grid-cols-2">
          <VerticalLayout className="md:pr-4">
            <div className="flex flex-col gap-2">
              <Typography variant="heading1" className="uppercase">
                {capitalize(goalOwner)}’s Goal
              </Typography>
              <Typography variant="label">
                {userGoal?.product?.name && userGoal?.product?.name}
              </Typography>
            </div>
            <Progress
              userGoal={userGoal}
              itemPrice={itemPrice}
              goalOwner={goalOwner}
              payment={payment}
              chipInTotal={chipInTotal}
              transactionsTotal={transactionsTotal}
              loading={loading}
              complete={!canChipIn}
            />
          </VerticalLayout>
          <VerticalLayout className="md:pl-4">
            <div className="flex flex-col">
              {canChipIn && (
                <PaymentForm
                  itemPrice={itemPrice}
                  goalOwner={goalOwner}
                  goalUpdated={getGoal}
                  userGoal={userGoal}
                  goalId={goalId}
                  chipInTotal={chipInTotal}
                  transactionsTotal={transactionsTotal}
                  userId={userId}
                  amountRemainingToBePaid={amountRemainingToBePaid}
                  userName={firstName}
                />
              )}
              {!canChipIn && (
                <div className="flex flex-col gap-2">
                  <Typography variant="heading1">This Reel has been fully funded</Typography>
                  <Typography variant="heading2">and is not accepting any more Chip ins</Typography>
                  <Typography variant="body-large">
                    What about you? Start saving for what you love today!
                  </Typography>
                  <a
                    href={process.env.REACT_APP_REDIRECT_LINK + utmParams}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Button>GO TO SUMA WEALTH</Button>
                  </a>
                </div>
              )}
            </div>
          </VerticalLayout>
        </div>
      </>
    ) : (
      <>
        <Helmet>
          <title>Page Not Found | Suma</title>
        </Helmet>
        <VerticalLayout className="max-w-2xl m-auto" gapClassName="gap-3 md:gap-6">
          <Typography variant="heading1">
            Uh oh! We can&apos;t find the page you&apos;re looking for.
          </Typography>
          <VerticalLayout className="w-4/5 md:w-3/5 mx-auto" gapClassName="gap-3 md:gap-6">
            <img
              width={789}
              height={600}
              src={notFoundImg}
              alt="A power cable has become unplugged. Text says: 'Error Code: 404'."
              className="object-contain max-w-xs ml-10"
            />
            <a href={process.env.REACT_APP_REDIRECT_LINK + utmParams}>
              <Button>Visit Home Page</Button>
            </a>
          </VerticalLayout>
        </VerticalLayout>
      </>
    );
  }

  function capitalize(s) {
    if (s) {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    } else {
      return '';
    }
  }

  if (loading) {
    return (
      <div className="cursor-pointer">
        <LoadingSpinner />
      </div>
    );
  }

  return goalContent();
};

export default Goal;
