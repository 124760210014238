import React, { useReducer } from 'react';
import paymentReducer from '../reducers/paymentReducer';

export const PaymentContext = React.createContext();

const PaymentContextProvider = (props) => {
  const [payment, dispatch] = useReducer(paymentReducer, {
    amount: 0,
    fullAmount: 0,
    fullBalance: false,
  });

  return (
    <PaymentContext.Provider value={{ payment, dispatch }}>
      {props.children}
    </PaymentContext.Provider>
  );
};

export default PaymentContextProvider;
