import React, { useEffect, useRef } from 'react';
import ImageWithFallback from '../../molecules/ImageWithFallback';

export interface CheckboxProps extends Omit<React.HTMLAttributes<HTMLInputElement>, 'name'> {
  progress: number;
  imageSrc?: ImageProps['src'];
  sizes?: string;
  imageOverlay?: React.ReactNode;
  loading?: boolean;
  lineStyle?: any;
  isComplete?: boolean;
}

const ProgressWheel: React.FC<CheckboxProps> = ({
  progress,
  imageSrc,
  imageOverlay,
  loading = false,
  sizes,
  lineStyle,
  isComplete = false,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  function setFillPathsUser(percentageChip: number, percentageUser: number) {
    const svgEl = svgRef.current;
    if (svgEl) {
      const pathEls = svgEl.querySelectorAll('path[id*="progress_wheel"]');

      let userProgress = +percentageUser * 100;

      let chipProgress = +percentageChip * 100;

      for (let i = 0; i < pathEls.length; i++) {
        const pathEl = pathEls[i] as SVGPathElement;
        const pathId = pathEl.getAttribute('id');
        if (pathId) {
          let progressWheelId = +pathId.replace('progress_wheel_', '');

          //user
          if (progressWheelId <= userProgress) {
            pathEl.classList.add('user');
          } else if (chipProgress >= progressWheelId && progressWheelId > userProgress) {
            pathEl.classList.add('chip');
          }
        }
      }
    }
  }

  useEffect(() => {
    if (lineStyle && !isComplete && lineStyle.total && lineStyle.user) {
      setFillPathsUser(lineStyle.total.percentage, lineStyle.user.percentage);
    }
  }, [lineStyle]);

  if (isComplete) {
    return (
      <div className="relative">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="595.3px"
          height="591px"
          viewBox="0 0 595.3 591"
          className="progress-wheel-component"
          xmlSpace="preserve"
        >
          <g id="Layer_1">
            <g>
              <path
                className={progress > 86 ? 'st0' : ''}
                d="M555.1,452.8c4.4-7.7,8.5-15.6,12.2-23.7l-39.6-18.8c-3.4,6.8-7,13.5-11,20L555.1,452.8z"
              />
              <path
                className={progress > 83 ? 'st1' : ''}
                d="M572.6,416.8c3.4-8.2,6.4-16.6,9-25.1l-40.7-13.2c-2.5,7.2-5.2,14.3-8.3,21.3L572.6,416.8z"
              />
              <path
                className={progress > 80 ? 'st2' : ''}
                d="M585.2,379c2.3-8.6,4.1-17.2,5.6-26l-41-7.6c-1.5,7.5-3.3,14.9-5.5,22.2L585.2,379z"
              />
              <path
                className={progress > 77 ? 'st3' : ''}
                d="M592.9,339.9c1.1-8.8,1.9-17.6,2.2-26.5l-40.7-2.1c-0.5,7.6-1.4,15.3-2.5,22.8L592.9,339.9z"
              />
              <path
                className={progress > 74 ? 'st4' : ''}
                d="M595.3,300.2c0-8.8-0.5-17.7-1.3-26.5l-39.5,3.2c0.5,7.7,0.7,15.3,0.5,23L595.3,300.2z"
              />
              <path
                className={progress > 72 ? 'st5' : ''}
                d="M592.5,260.5c-1.2-8.7-2.8-17.4-4.8-26l-37.8,8.1c1.5,7.5,2.7,15.1,3.5,22.8L592.5,260.5z"
              />
              <path
                className={progress > 70 ? 'st6' : ''}
                d="M584.5,221.7c-2.3-8.5-5-16.9-8.1-25.1L541,209.2c2.5,7.3,4.6,14.7,6.5,22.2L584.5,221.7z"
              />
              <path
                className={progress > 68 ? 'st7' : ''}
                d="M571.6,184.3c-3.4-8.1-7.2-16-11.2-23.8l-32.6,16.6c3.4,6.9,6.5,14,9.3,21.2L571.6,184.3z"
              />
              <path
                className={progress > 66 ? 'st8' : ''}
                d="M553.9,149c-4.4-7.6-9.2-15-14.2-22.1L510.4,147c4.3,6.4,8.3,13.1,12,19.8L553.9,149z"
              />
              <path
                className={progress > 64 ? 'st9' : ''}
                d="M531.9,116.3c-5.4-6.9-11-13.6-17-20l-25.6,23.1c5.1,5.8,9.9,11.9,14.5,18.1L531.9,116.3z"
              />
              <path
                className={progress > 62 ? 'st10' : ''}
                d="M505.7,86.8c-6.2-6.2-12.7-12-19.4-17.6l-21.7,25.5c5.8,5.1,11.4,10.5,16.8,16.1L505.7,86.8z"
              />
              <path
                className={progress > 60 ? 'st11' : ''}
                d="M476,61.1c-7-5.3-14.1-10.2-21.5-14.9l-17.6,27.2c6.5,4.3,12.7,8.9,18.8,13.8L476,61.1z"
              />
              <path
                className={progress > 58 ? 'st12' : ''}
                d="M443.3,39.5c-7.6-4.3-15.3-8.3-23.2-11.9l-13.5,28.4c7,3.5,13.8,7.2,20.5,11.2L443.3,39.5z"
              />
              <path
                className={progress > 56 ? 'st13' : ''}
                d="M408.1,22.4c-8.1-3.3-16.2-6.2-24.5-8.8l-9.4,28.9c7.4,2.5,14.7,5.4,21.8,8.5L408.1,22.4z"
              />
              <path
                className={progress > 54 ? 'st14' : ''}
                d="M371,10c-8.4-2.2-16.9-4.1-25.4-5.5l-5.3,28.8c7.7,1.5,15.3,3.4,22.8,5.6L371,10z"
              />
              <path
                className={progress > 52 ? 'st15' : ''}
                d="M332.7,2.5c-8.6-1.1-17.3-1.8-25.9-2.1l-1.5,28.3c7.8,0.5,15.6,1.4,23.3,2.6L332.7,2.5z"
              />
              <path
                className={progress > 49 ? 'st16' : ''}
                d="M293.8,0.2c-8.7,0-17.3,0.5-25.9,1.3l2.2,27.2c7.8-0.5,15.7-0.7,23.5-0.5L293.8,0.2z"
              />
              <path
                className={progress > 47 ? 'st17' : ''}
                d="M255,2.9c-8.6,1.2-17.1,2.7-25.5,4.7l5.5,25.6c7.7-1.5,15.5-2.7,23.3-3.6L255,2.9z"
              />
              <path
                className={progress > 45 ? 'st18' : ''}
                d="M216.9,10.7c-8.3,2.3-16.5,4.9-24.6,7.9l8.4,23.7c7.5-2.5,15-4.7,22.7-6.6L216.9,10.7z"
              />
              <path
                className={progress > 43 ? 'st19' : ''}
                d="M180.3,23.4c-7.9,3.3-15.7,7-23.3,11L168,56c7.1-3.5,14.3-6.7,21.7-9.5L180.3,23.4z"
              />
              <path
                className={progress > 41 ? 'st20' : ''}
                d="M145.7,40.7c-7.4,4.3-14.7,9-21.7,13.9l13.1,19.1c6.6-4.4,13.4-8.5,20.3-12.3L145.7,40.7z"
              />
              <path
                className={progress > 39 ? 'st21' : ''}
                d="M113.7,62.3c-6.8,5.3-13.3,10.8-19.6,16.6l14.8,16.4c6-5.2,12.2-10.2,18.5-14.9L113.7,62.3z"
              />
              <path
                className={progress > 36 ? 'st22' : ''}
                d="M84.8,87.9c-6,6.1-11.8,12.5-17.2,19l16,13.6c5.2-6,10.7-11.7,16.5-17.2L84.8,87.9z"
              />
              <path
                className={progress > 34 ? 'st23' : ''}
                d="M59.6,117c-5.2,6.8-10,13.9-14.6,21.1l16.8,10.9c4.4-6.6,9.1-13,14.1-19.2L59.6,117z"
              />
              <path
                className={progress > 32 ? 'st24' : ''}
                d="M38.5,149.1c-4.2,7.4-8.1,15-11.7,22.8l17.1,8.2c3.5-7.2,7.4-14.1,11.5-21L38.5,149.1z"
              />
              <path
                className={progress > 30 ? 'st25' : ''}
                d="M21.7,183.5c-3.2,7.9-6.1,15.9-8.6,24l17.1,5.5c2.6-7.6,5.5-15,8.7-22.3L21.7,183.5z"
              />
              <path
                className={progress > 28 ? 'st26' : ''}
                d="M9.6,219.9c-2.2,8.2-4,16.5-5.4,24.9l16.6,3.1c1.6-7.8,3.5-15.6,5.7-23.3L9.6,219.9z"
              />
              <path
                className={progress > 26 ? 'st27' : ''}
                d="M2.3,257.4c-1.1,8.4-1.8,16.9-2.1,25.4l15.8,0.8c0.5-8,1.4-16,2.6-23.9L2.3,257.4z"
              />
              <path
                className={progress > 24 ? 'st28' : ''}
                d="M0,295.5c0,8.5,0.5,17,1.2,25.4l14.8-1.2c-0.5-8-0.7-16.1-0.5-24.1L0,295.5z"
              />
              <path
                className={progress > 22 ? 'st29' : ''}
                d="M2.7,333.5c1.2,8.4,2.7,16.7,4.6,24.9l13.5-2.9c-1.6-7.9-2.8-15.8-3.7-23.8L2.7,333.5z"
              />
              <path
                className={progress > 20 ? 'st30' : ''}
                d="M10.4,370.7c2.2,8.1,4.8,16.2,7.8,24.1l12-4.3c-2.6-7.6-4.9-15.4-6.8-23.2L10.4,370.7z"
              />
              <path
                className={progress > 18 ? 'st31' : ''}
                d="M22.8,406.5c3.3,7.8,6.9,15.4,10.8,22.8l10.5-5.3c-3.6-7.3-6.8-14.7-9.7-22.2L22.8,406.5z"
              />
              <path
                className={progress > 15 ? 'st32' : ''}
                d="M39.7,440.4c4.2,7.3,8.8,14.4,13.7,21.2l8.8-6.1c-4.5-6.7-8.7-13.7-12.6-20.8L39.7,440.4z"
              />
              <path
                className={progress > 12 ? 'st33' : ''}
                d="M60.9,471.8c5.1,6.6,10.6,13.1,16.3,19.2l7.2-6.5c-5.3-6.1-10.4-12.4-15.2-19L60.9,471.8z"
              />
              <path
                className={progress > 9 ? 'st34' : ''}
                d="M85.9,500c6,5.9,12.2,11.5,18.6,16.9l5.6-6.6c-6.1-5.4-12-11-17.6-16.8L85.9,500z"
              />
              <path
                className={progress > 6 ? 'st35' : ''}
                d="M114.4,524.7c6.7,5.1,13.6,9.8,20.6,14.3l4.1-6.4c-6.8-4.5-13.3-9.3-19.7-14.4L114.4,524.7z"
              />
              <path
                className={progress > 3 ? 'st36' : ''}
                d="M145.8,545.4c7.3,4.1,14.7,7.9,22.3,11.4l2.8-5.9c-7.3-3.6-14.5-7.5-21.4-11.8L145.8,545.4z"
              />
            </g>
          </g>
          <g id="Layer_2">
            <path
              className={progress > 98 ? 'st37' : ''}
              d="M419.9,580.8c0.1,0,0.2-0.1,0.3-0.1c0.4-0.2,0.7-0.4,1-0.5c7.9-4.6,8.6-15.8,1.9-21.9l-3.3-3
    c-5.8-5.2-10.4-11.5-13.8-18.5l-1.1-2.3c-0.6-1.2-1.5-2.3-2.8-2.7c-1.3-0.5-2.8-0.5-4.1,0.1c0,0,0,0,0,0c-2.4,1-2.7,2.4-3.3,6.1
    l0.1,7.9c0.1,6,1,11.9,2.8,17.6l3.2,9.9C403.8,580.9,412.6,584.3,419.9,580.8z"
            />
            <path
              className={progress > 96 ? 'st38' : ''}
              d="M455.6,559.5c0.1-0.1,0.3-0.2,0.4-0.2c0.3-0.2,0.7-0.4,1-0.7c7.3-5.6,6.3-17-1.5-21.9l-1.8-1.1
    c-7.2-4.5-13.4-10.6-18.1-17.7l-0.4-0.7c-1.7-2.6-5.2-3.5-7.7-1.8c0,0,0,0-0.1,0c0,0,0,0,0,0c-2.2,1.3-2.3,4.1-2.5,6.4l0.7,5.3
    c0.9,6.9,3.1,13.7,6.4,19.9l4,7.5C439.7,561.6,448.8,563.9,455.6,559.5z"
            />
            <path
              className={progress > 94 ? 'st39' : ''}
              d="M490.2,535.5c0.1-0.1,0.2-0.2,0.3-0.3c5.2-4.4,6.4-11.7,3.2-17.4c-0.9-1.7-2.4-2.9-4.2-3.6l0,0
    c-9.6-3.9-18-10.2-24.4-18.3l-0.4-0.6c-1.9-2.5-5.5-3.2-8.1-1.3c-0.2,0.1-0.4,0.3-0.5,0.4c0,0,0,0,0,0c-2,1.8-2,4.1-1.4,7.1l0.9,4
    c1.9,8.1,5.4,15.7,10.4,22.3l4,5.3C474.8,539.6,484,540.7,490.2,535.5z"
            />
            <path
              className={progress > 92 ? 'st40' : ''}
              d="M518.4,508.2c0.1-0.1,0.2-0.2,0.2-0.3c1.1-1.2,1.9-2.5,2.5-3.9c3.1-7.2-1.2-15.4-8.6-17.7l-0.5-0.1
    c-9.4-2.9-17.9-8.1-24.7-15.2v0c-2.5-2.6-6-2.2-7.8,1c0,0.1-1.8,3.2-0.7,7.5l0.7,2.1c2.9,8.2,7.5,15.8,13.6,22l4.9,5
    C503.7,514.4,513,514.2,518.4,508.2z"
            />
            <path
              className={progress > 90 ? 'st41' : ''}
              d="M544.2,477.8c0.1-0.1,0.1-0.2,0.2-0.2c0.2-0.3,0.4-0.7,0.6-1c4.5-7.8-0.3-17.8-9.1-19.8l-3.6-0.8
    c-6.9-1.6-13.5-4.4-19.5-8.3l-4.1-2.7c-2.8-1.2-5.9-0.2-7.6,2.3c0,0,0,0,0,0.1c-1.5,2.1-0.9,4.9,0.2,7l1.2,2.4
    c3.8,7.6,9.2,14.3,15.9,19.6l5.7,4.6C530.4,485.8,539.6,484.4,544.2,477.8z"
            />
            <g>
              <g>
                <g>
                  <path
                    className={progress > 90 ? 'st42' : ''}
                    d="M548.5,468.1c0.4,4.5-1,9-4,12.4c-2.5,2.7-6,4.7-9.8,4.8c-2,0-2,3.1,0,3.1c4.6,0,8.9-2.4,11.9-5.7
          c3.6-3.9,5.4-9.2,4.9-14.5C551.4,466.1,548.3,466.1,548.5,468.1L548.5,468.1z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    className={progress > 90 ? 'st42' : ''}
                    d="M544.1,482.4c1.9,3,5.6,4.6,9.1,3.8c2-0.4,3.7-1.6,5.2-2.8c0.7-0.5,0.5-1.7,0-2.2c-0.6-0.6-1.5-0.5-2.2,0
          c-0.8,0.6-1.6,1.1-2.5,1.5c-0.2,0.1-0.2,0.1-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0.1-0.4,0.1-0.6,0.1
          c-0.1,0-0.7,0.1-0.3,0.1c-0.3,0-0.7,0.1-1,0c-0.1,0-0.2,0-0.4,0c-0.2,0-0.2,0,0.1,0c-0.1,0-0.2,0-0.2,0
          c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1,0-0.3-0.1-0.4-0.1c0.4,0.2,0,0-0.1,0c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.7-0.6-0.4-0.2
          c-0.3-0.3-0.6-0.5-0.9-0.8c-0.1-0.1-0.2-0.2-0.3-0.4c0,0-0.1-0.1-0.1-0.1c-0.2-0.2,0.2,0.3,0.1,0.1c-0.1-0.1-0.1-0.2-0.2-0.3
          c-0.4-0.7-1.4-1-2.1-0.6C544,480.7,543.7,481.7,544.1,482.4L544.1,482.4z"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    className={progress > 92 ? 'st42' : ''}
                    d="M523.7,497c0.4,4.5-1,9-4,12.4c-2.5,2.7-6,4.7-9.8,4.8c-2,0-2,3.1,0,3.1c4.6,0,8.9-2.4,11.9-5.7
          c3.6-3.9,5.4-9.2,4.9-14.5C526.6,495,523.5,495,523.7,497L523.7,497z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    className={progress > 92 ? 'st42' : ''}
                    d="M519.3,511.3c1.9,3,5.6,4.6,9.1,3.8c2-0.4,3.7-1.6,5.2-2.8c0.7-0.5,0.5-1.7,0-2.2c-0.6-0.6-1.5-0.5-2.2,0
          c-0.8,0.6-1.6,1.1-2.5,1.5c-0.2,0.1-0.2,0.1-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0.1-0.4,0.1-0.6,0.1
          c-0.1,0-0.7,0.1-0.3,0.1c-0.3,0-0.7,0.1-1,0c-0.1,0-0.2,0-0.4,0c-0.2,0-0.2,0,0.1,0c-0.1,0-0.2,0-0.2,0
          c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1,0-0.3-0.1-0.4-0.1c0.4,0.2,0,0-0.1,0c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.7-0.6-0.4-0.2
          c-0.3-0.3-0.6-0.5-0.9-0.8c-0.1-0.1-0.2-0.2-0.3-0.4c0,0-0.1-0.1-0.1-0.1c-0.2-0.2,0.2,0.3,0.1,0.1c-0.1-0.1-0.1-0.2-0.2-0.3
          c-0.4-0.7-1.4-1-2.1-0.6C519.2,509.6,518.9,510.6,519.3,511.3L519.3,511.3z"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    className={progress > 94 ? 'st42' : ''}
                    d="M497.2,524.5c0,4.5-1.9,8.8-5.2,11.9c-2.8,2.5-6.5,4.1-10.2,3.8c-0.8-0.1-1.6,0.8-1.6,1.6
          c0,0.9,0.7,1.5,1.6,1.6c7.5,0.6,14.4-4.9,17.2-11.6c0.9-2.3,1.3-4.7,1.4-7.2C500.3,522.5,497.2,522.5,497.2,524.5L497.2,524.5z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    className={progress > 94 ? 'st42' : ''}
                    d="M491.6,538.3c0.5,0.9,1.2,1.8,2,2.5c1.9,1.6,4.2,2.3,6.7,1.9c1.9-0.3,3.6-1.1,5.2-2.1
          c0.7-0.5,1-1.4,0.6-2.1c-0.4-0.7-1.4-1-2.1-0.6c-0.7,0.5-1.4,0.9-2.2,1.2c0.1-0.1-0.2,0.1-0.2,0.1c-0.1,0.1-0.3,0.1-0.5,0.2
          c-0.3,0.1-0.7,0.2-1,0.3c-0.1,0-0.3,0.1-0.4,0.1c0.3,0,0,0-0.1,0c-0.3,0-0.6,0-0.9,0c-0.1,0-0.2,0-0.4,0c-0.3,0,0.2,0.1,0,0
          c-0.2-0.1-0.5-0.1-0.7-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.2-0.1,0,0c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.5-0.3
          c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.1,0.3,0.2,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.3,0.2,0.2,0-0.1
          c-0.2-0.3-0.4-0.5-0.5-0.8c-0.4-0.7-1.4-1-2.1-0.6C491.4,536.7,491.3,537.6,491.6,538.3L491.6,538.3z"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    className={progress > 96 ? 'st42' : ''}
                    d="M463.5,550.5c-0.9,4.5-3.4,8.4-7.3,10.8c-3.2,1.9-7.1,2.9-10.7,1.9c-1.9-0.5-2.8,2.5-0.8,3
          c4.4,1.2,9.2,0.2,13.1-2.2c4.6-2.8,7.7-7.4,8.7-12.6C466.8,549.4,463.8,548.5,463.5,550.5L463.5,550.5z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    className={progress > 96 ? 'st42' : ''}
                    d="M455.3,563.1c1,3.4,4.1,5.9,7.7,6.1c1.9,0.1,3.8-0.4,5.5-1.1c0.8-0.3,1.3-1,1.1-1.9
          c-0.2-0.7-1.1-1.4-1.9-1.1c-0.8,0.3-1.6,0.6-2.4,0.8c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.5,0.1-0.2,0c-0.3,0-0.7,0.1-1,0.1
          c-0.3,0-0.6,0-0.8,0c-0.3,0,0.3,0.1-0.1,0c-0.2,0-0.3-0.1-0.5-0.1c-0.2-0.1-0.5-0.1-0.7-0.2c0,0-0.1,0-0.1-0.1
          c-0.2-0.1,0.3,0.1,0.1,0c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1,0.1,0.1,0.1,0.1
          c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.6-0.5-0.9-0.9c-0.1-0.1-0.1-0.2-0.2-0.2c0.2,0.1,0.2,0.3,0,0.1c-0.1-0.1-0.2-0.3-0.3-0.4
          c-0.2-0.3-0.3-0.5-0.4-0.8c0-0.1-0.2-0.5-0.1-0.2c0.1,0.3,0,0,0-0.1c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.8-1.1-1.3-1.9-1.1
          C455.6,561.4,455.1,562.3,455.3,563.1L455.3,563.1z"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    className={progress > 98 ? 'st42' : ''}
                    d="M428.9,572.4c-1.3,4.4-4.2,8-8.3,10c-3.3,1.7-7.3,2.2-10.8,0.8c-1.9-0.7-2.7,2.3-0.8,3
          c4.3,1.6,9.2,0.8,13.2-1.2c4.8-2.4,8.2-6.8,9.7-11.9C432.5,571.3,429.5,570.5,428.9,572.4L428.9,572.4z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    className={progress > 98 ? 'st42' : ''}
                    d="M419.8,584.3c0.7,3.4,3.7,6.1,7.1,6.6c1.9,0.3,3.9,0,5.7-0.6c0.8-0.3,1.3-1.1,1.1-1.9
          c-0.2-0.8-1.1-1.3-1.9-1.1c-0.4,0.1-0.8,0.3-1.3,0.4c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.7,0.1-0.4,0.1c-0.7,0.1-1.4,0.1-2,0.1
          c-0.1,0-0.5-0.1-0.2,0c0.2,0-0.2,0-0.3-0.1c-0.3-0.1-0.6-0.1-0.8-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1,0.3,0.1,0.1,0
          c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.5-0.2-0.7-0.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.2,0.2,0.2,0,0c-0.2-0.2-0.3-0.3-0.5-0.5
          c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.1-0.3,0,0c0-0.1-0.1-0.2-0.2-0.2c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1-0.1-0.1-0.3-0.2-0.4
          c-0.1-0.2,0.2,0.4,0.1,0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.3-0.2-0.6-0.3-0.9c-0.2-0.8-1.1-1.3-1.9-1.1
          C420,582.6,419.7,583.5,419.8,584.3L419.8,584.3z"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
        <div className="absolute flex justify-center items-center w-full h-full top-0 left-0">
          <div
            className="z-10 rounded-full w-5/6 h-5/6 overflow-hidden"
            style={{ marginLeft: '-20px' }}
          >
            {imageOverlay}
            <div className="relative w-full h-full">
              <ImageWithFallback
                src={
                  typeof imageSrc === 'string' && imageSrc.startsWith('//')
                    ? `https:${imageSrc}`
                    : !imageSrc
                    ? require('../../../assets/images/Savings-Summary-Icon.svg')
                    : imageSrc
                }
                alt="Suma wealth product"
                placeholder={loading ? 'blur' : undefined}
                sizes={sizes}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <svg
        ref={svgRef}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="595.3px"
        height="591px"
        viewBox="0 0 595.3 591"
        className="progress-wheel-component"
        xmlSpace="preserve"
      >
        <g id="Layer_1">
          <g>
            <path
              id="progress_wheel_86"
              // className={progress > 86 ? 'st0' : ''}
              d="M555.1,452.8c4.4-7.7,8.5-15.6,12.2-23.7l-39.6-18.8c-3.4,6.8-7,13.5-11,20L555.1,452.8z"
            />
            <path
              id="progress_wheel_83"
              // className={progress > 83 ? 'st1' : ''}
              d="M572.6,416.8c3.4-8.2,6.4-16.6,9-25.1l-40.7-13.2c-2.5,7.2-5.2,14.3-8.3,21.3L572.6,416.8z"
            />
            <path
              id="progress_wheel_80"
              // className={progress > 80 ? 'st2' : ''}
              d="M585.2,379c2.3-8.6,4.1-17.2,5.6-26l-41-7.6c-1.5,7.5-3.3,14.9-5.5,22.2L585.2,379z"
            />
            <path
              id="progress_wheel_77"
              // className={progress > 77 ? 'st3' : ''}
              d="M592.9,339.9c1.1-8.8,1.9-17.6,2.2-26.5l-40.7-2.1c-0.5,7.6-1.4,15.3-2.5,22.8L592.9,339.9z"
            />
            <path
              id="progress_wheel_74"
              // className={progress > 74 ? 'st4' : ''}
              d="M595.3,300.2c0-8.8-0.5-17.7-1.3-26.5l-39.5,3.2c0.5,7.7,0.7,15.3,0.5,23L595.3,300.2z"
            />
            <path
              id="progress_wheel_72"
              // className={progress > 72 ? 'st5' : ''}
              d="M592.5,260.5c-1.2-8.7-2.8-17.4-4.8-26l-37.8,8.1c1.5,7.5,2.7,15.1,3.5,22.8L592.5,260.5z"
            />
            <path
              id="progress_wheel_70"
              // className={progress > 70 ? 'st6' : ''}
              d="M584.5,221.7c-2.3-8.5-5-16.9-8.1-25.1L541,209.2c2.5,7.3,4.6,14.7,6.5,22.2L584.5,221.7z"
            />
            <path
              id="progress_wheel_68"
              // className={progress > 68 ? 'st7' : ''}
              d="M571.6,184.3c-3.4-8.1-7.2-16-11.2-23.8l-32.6,16.6c3.4,6.9,6.5,14,9.3,21.2L571.6,184.3z"
            />
            <path
              id="progress_wheel_66"
              // className={progress > 66 ? 'st8' : ''}
              d="M553.9,149c-4.4-7.6-9.2-15-14.2-22.1L510.4,147c4.3,6.4,8.3,13.1,12,19.8L553.9,149z"
            />
            <path
              id="progress_wheel_64"
              // className={progress > 64 ? 'st9' : ''}
              d="M531.9,116.3c-5.4-6.9-11-13.6-17-20l-25.6,23.1c5.1,5.8,9.9,11.9,14.5,18.1L531.9,116.3z"
            />
            <path
              id="progress_wheel_62"
              // className={progress > 62 ? 'st10' : ''}
              d="M505.7,86.8c-6.2-6.2-12.7-12-19.4-17.6l-21.7,25.5c5.8,5.1,11.4,10.5,16.8,16.1L505.7,86.8z"
            />
            <path
              id="progress_wheel_60"
              // className={progress > 60 ? 'st11' : ''}
              d="M476,61.1c-7-5.3-14.1-10.2-21.5-14.9l-17.6,27.2c6.5,4.3,12.7,8.9,18.8,13.8L476,61.1z"
            />
            <path
              id="progress_wheel_58"
              // className={progress > 58 ? 'st12' : ''}
              d="M443.3,39.5c-7.6-4.3-15.3-8.3-23.2-11.9l-13.5,28.4c7,3.5,13.8,7.2,20.5,11.2L443.3,39.5z"
            />
            <path
              id="progress_wheel_56"
              // className={progress > 56 ? 'st13' : ''}
              d="M408.1,22.4c-8.1-3.3-16.2-6.2-24.5-8.8l-9.4,28.9c7.4,2.5,14.7,5.4,21.8,8.5L408.1,22.4z"
            />
            <path
              id="progress_wheel_54"
              // className={progress > 54 ? 'st14' : ''}
              d="M371,10c-8.4-2.2-16.9-4.1-25.4-5.5l-5.3,28.8c7.7,1.5,15.3,3.4,22.8,5.6L371,10z"
            />
            <path
              id="progress_wheel_52"
              // className={progress > 52 ? 'st15' : ''}
              d="M332.7,2.5c-8.6-1.1-17.3-1.8-25.9-2.1l-1.5,28.3c7.8,0.5,15.6,1.4,23.3,2.6L332.7,2.5z"
            />
            <path
              id="progress_wheel_49"
              // className={progress > 49 ? 'st16' : ''}
              d="M293.8,0.2c-8.7,0-17.3,0.5-25.9,1.3l2.2,27.2c7.8-0.5,15.7-0.7,23.5-0.5L293.8,0.2z"
            />
            <path
              id="progress_wheel_47"
              // className={progress > 47 ? 'st17' : ''}
              d="M255,2.9c-8.6,1.2-17.1,2.7-25.5,4.7l5.5,25.6c7.7-1.5,15.5-2.7,23.3-3.6L255,2.9z"
            />
            <path
              id="progress_wheel_45"
              // className={progress > 45 ? 'st18' : ''}
              d="M216.9,10.7c-8.3,2.3-16.5,4.9-24.6,7.9l8.4,23.7c7.5-2.5,15-4.7,22.7-6.6L216.9,10.7z"
            />
            <path
              id="progress_wheel_43"
              // className={progress > 43 ? 'st19' : ''}
              d="M180.3,23.4c-7.9,3.3-15.7,7-23.3,11L168,56c7.1-3.5,14.3-6.7,21.7-9.5L180.3,23.4z"
            />
            <path
              id="progress_wheel_41"
              // className={progress > 41 ? 'st20' : ''}
              d="M145.7,40.7c-7.4,4.3-14.7,9-21.7,13.9l13.1,19.1c6.6-4.4,13.4-8.5,20.3-12.3L145.7,40.7z"
            />
            <path
              id="progress_wheel_39"
              // className={progress > 39 ? 'st21' : ''}
              d="M113.7,62.3c-6.8,5.3-13.3,10.8-19.6,16.6l14.8,16.4c6-5.2,12.2-10.2,18.5-14.9L113.7,62.3z"
            />
            <path
              id="progress_wheel_36"
              // className={progress > 36 ? 'st22' : ''}
              d="M84.8,87.9c-6,6.1-11.8,12.5-17.2,19l16,13.6c5.2-6,10.7-11.7,16.5-17.2L84.8,87.9z"
            />
            <path
              id="progress_wheel_34"
              // className={progress > 34 ? 'st23' : ''}
              d="M59.6,117c-5.2,6.8-10,13.9-14.6,21.1l16.8,10.9c4.4-6.6,9.1-13,14.1-19.2L59.6,117z"
            />
            <path
              id="progress_wheel_32"
              // className={progress > 32 ? 'st24' : ''}
              d="M38.5,149.1c-4.2,7.4-8.1,15-11.7,22.8l17.1,8.2c3.5-7.2,7.4-14.1,11.5-21L38.5,149.1z"
            />
            <path
              id="progress_wheel_30"
              // className={progress > 30 ? 'st25' : ''}
              d="M21.7,183.5c-3.2,7.9-6.1,15.9-8.6,24l17.1,5.5c2.6-7.6,5.5-15,8.7-22.3L21.7,183.5z"
            />
            <path
              id="progress_wheel_28"
              // className={progress > 28 ? 'st26' : ''}
              d="M9.6,219.9c-2.2,8.2-4,16.5-5.4,24.9l16.6,3.1c1.6-7.8,3.5-15.6,5.7-23.3L9.6,219.9z"
            />
            <path
              id="progress_wheel_26"
              // className={progress > 26 ? 'st27' : ''}
              d="M2.3,257.4c-1.1,8.4-1.8,16.9-2.1,25.4l15.8,0.8c0.5-8,1.4-16,2.6-23.9L2.3,257.4z"
            />
            <path
              id="progress_wheel_24"
              // className={progress > 24 ? 'st28' : ''}
              d="M0,295.5c0,8.5,0.5,17,1.2,25.4l14.8-1.2c-0.5-8-0.7-16.1-0.5-24.1L0,295.5z"
            />
            <path
              id="progress_wheel_22"
              // className={progress > 22 ? 'st29' : ''}
              d="M2.7,333.5c1.2,8.4,2.7,16.7,4.6,24.9l13.5-2.9c-1.6-7.9-2.8-15.8-3.7-23.8L2.7,333.5z"
            />
            <path
              id="progress_wheel_20"
              // className={progress > 20 ? 'st30' : ''}
              d="M10.4,370.7c2.2,8.1,4.8,16.2,7.8,24.1l12-4.3c-2.6-7.6-4.9-15.4-6.8-23.2L10.4,370.7z"
            />
            <path
              id="progress_wheel_18"
              // className={progress > 18 ? 'st31' : ''}
              d="M22.8,406.5c3.3,7.8,6.9,15.4,10.8,22.8l10.5-5.3c-3.6-7.3-6.8-14.7-9.7-22.2L22.8,406.5z"
            />
            <path
              id="progress_wheel_15"
              // className={progress > 15 ? 'st32' : ''}
              d="M39.7,440.4c4.2,7.3,8.8,14.4,13.7,21.2l8.8-6.1c-4.5-6.7-8.7-13.7-12.6-20.8L39.7,440.4z"
            />
            <path
              id="progress_wheel_12"
              // className={progress > 12 ? 'st33' : ''}
              d="M60.9,471.8c5.1,6.6,10.6,13.1,16.3,19.2l7.2-6.5c-5.3-6.1-10.4-12.4-15.2-19L60.9,471.8z"
            />
            <path
              id="progress_wheel_9"
              // className={progress > 9 ? 'st34' : ''}
              d="M85.9,500c6,5.9,12.2,11.5,18.6,16.9l5.6-6.6c-6.1-5.4-12-11-17.6-16.8L85.9,500z"
            />
            <path
              id="progress_wheel_6"
              // className={progress > 6 ? 'st35' : ''}
              d="M114.4,524.7c6.7,5.1,13.6,9.8,20.6,14.3l4.1-6.4c-6.8-4.5-13.3-9.3-19.7-14.4L114.4,524.7z"
            />
            <path
              className="bg-black"
              id="progress_wheel_3"
              // className={progress > 3 ? 'st36' : ''}
              d="M145.8,545.4c7.3,4.1,14.7,7.9,22.3,11.4l2.8-5.9c-7.3-3.6-14.5-7.5-21.4-11.8L145.8,545.4z"
            />
          </g>
        </g>
        <g id="Layer_2">
          <path
            id="progress_wheel_98"
            // className={progress > 98 ? 'st37' : ''}
            d="M419.9,580.8c0.1,0,0.2-0.1,0.3-0.1c0.4-0.2,0.7-0.4,1-0.5c7.9-4.6,8.6-15.8,1.9-21.9l-3.3-3
  c-5.8-5.2-10.4-11.5-13.8-18.5l-1.1-2.3c-0.6-1.2-1.5-2.3-2.8-2.7c-1.3-0.5-2.8-0.5-4.1,0.1c0,0,0,0,0,0c-2.4,1-2.7,2.4-3.3,6.1
  l0.1,7.9c0.1,6,1,11.9,2.8,17.6l3.2,9.9C403.8,580.9,412.6,584.3,419.9,580.8z"
          />
          <path
            id="progress_wheel_96"
            // className={progress > 96 ? 'st38' : ''}
            d="M455.6,559.5c0.1-0.1,0.3-0.2,0.4-0.2c0.3-0.2,0.7-0.4,1-0.7c7.3-5.6,6.3-17-1.5-21.9l-1.8-1.1
  c-7.2-4.5-13.4-10.6-18.1-17.7l-0.4-0.7c-1.7-2.6-5.2-3.5-7.7-1.8c0,0,0,0-0.1,0c0,0,0,0,0,0c-2.2,1.3-2.3,4.1-2.5,6.4l0.7,5.3
  c0.9,6.9,3.1,13.7,6.4,19.9l4,7.5C439.7,561.6,448.8,563.9,455.6,559.5z"
          />
          <path
            id="progress_wheel_94"
            // className={progress > 94 ? 'st39' : ''}
            d="M490.2,535.5c0.1-0.1,0.2-0.2,0.3-0.3c5.2-4.4,6.4-11.7,3.2-17.4c-0.9-1.7-2.4-2.9-4.2-3.6l0,0
  c-9.6-3.9-18-10.2-24.4-18.3l-0.4-0.6c-1.9-2.5-5.5-3.2-8.1-1.3c-0.2,0.1-0.4,0.3-0.5,0.4c0,0,0,0,0,0c-2,1.8-2,4.1-1.4,7.1l0.9,4
  c1.9,8.1,5.4,15.7,10.4,22.3l4,5.3C474.8,539.6,484,540.7,490.2,535.5z"
          />
          <path
            id="progress_wheel_92"
            // className={progress > 92 ? 'st40' : ''}
            d="M518.4,508.2c0.1-0.1,0.2-0.2,0.2-0.3c1.1-1.2,1.9-2.5,2.5-3.9c3.1-7.2-1.2-15.4-8.6-17.7l-0.5-0.1
  c-9.4-2.9-17.9-8.1-24.7-15.2v0c-2.5-2.6-6-2.2-7.8,1c0,0.1-1.8,3.2-0.7,7.5l0.7,2.1c2.9,8.2,7.5,15.8,13.6,22l4.9,5
  C503.7,514.4,513,514.2,518.4,508.2z"
          />
          <path
            id="progress_wheel_90"
            // className={progress > 90 ? 'st41' : ''}
            d="M544.2,477.8c0.1-0.1,0.1-0.2,0.2-0.2c0.2-0.3,0.4-0.7,0.6-1c4.5-7.8-0.3-17.8-9.1-19.8l-3.6-0.8
  c-6.9-1.6-13.5-4.4-19.5-8.3l-4.1-2.7c-2.8-1.2-5.9-0.2-7.6,2.3c0,0,0,0,0,0.1c-1.5,2.1-0.9,4.9,0.2,7l1.2,2.4
  c3.8,7.6,9.2,14.3,15.9,19.6l5.7,4.6C530.4,485.8,539.6,484.4,544.2,477.8z"
          />
          <g>
            <g>
              <g>
                <path
                  id="progress_wheel_90"
                  // className={progress > 90 ? 'st42' : ''}
                  d="M548.5,468.1c0.4,4.5-1,9-4,12.4c-2.5,2.7-6,4.7-9.8,4.8c-2,0-2,3.1,0,3.1c4.6,0,8.9-2.4,11.9-5.7
        c3.6-3.9,5.4-9.2,4.9-14.5C551.4,466.1,548.3,466.1,548.5,468.1L548.5,468.1z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  id="progress_wheel_90"
                  // className={progress > 90 ? 'st42' : ''}
                  d="M544.1,482.4c1.9,3,5.6,4.6,9.1,3.8c2-0.4,3.7-1.6,5.2-2.8c0.7-0.5,0.5-1.7,0-2.2c-0.6-0.6-1.5-0.5-2.2,0
        c-0.8,0.6-1.6,1.1-2.5,1.5c-0.2,0.1-0.2,0.1-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0.1-0.4,0.1-0.6,0.1
        c-0.1,0-0.7,0.1-0.3,0.1c-0.3,0-0.7,0.1-1,0c-0.1,0-0.2,0-0.4,0c-0.2,0-0.2,0,0.1,0c-0.1,0-0.2,0-0.2,0
        c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1,0-0.3-0.1-0.4-0.1c0.4,0.2,0,0-0.1,0c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.7-0.6-0.4-0.2
        c-0.3-0.3-0.6-0.5-0.9-0.8c-0.1-0.1-0.2-0.2-0.3-0.4c0,0-0.1-0.1-0.1-0.1c-0.2-0.2,0.2,0.3,0.1,0.1c-0.1-0.1-0.1-0.2-0.2-0.3
        c-0.4-0.7-1.4-1-2.1-0.6C544,480.7,543.7,481.7,544.1,482.4L544.1,482.4z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  id="progress_wheel_92"
                  // className={progress > 92 ? 'st42' : ''}
                  d="M523.7,497c0.4,4.5-1,9-4,12.4c-2.5,2.7-6,4.7-9.8,4.8c-2,0-2,3.1,0,3.1c4.6,0,8.9-2.4,11.9-5.7
        c3.6-3.9,5.4-9.2,4.9-14.5C526.6,495,523.5,495,523.7,497L523.7,497z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  id="progress_wheel_92"
                  // className={progress > 92 ? 'st42' : ''}
                  d="M519.3,511.3c1.9,3,5.6,4.6,9.1,3.8c2-0.4,3.7-1.6,5.2-2.8c0.7-0.5,0.5-1.7,0-2.2c-0.6-0.6-1.5-0.5-2.2,0
        c-0.8,0.6-1.6,1.1-2.5,1.5c-0.2,0.1-0.2,0.1-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0.1-0.4,0.1-0.6,0.1
        c-0.1,0-0.7,0.1-0.3,0.1c-0.3,0-0.7,0.1-1,0c-0.1,0-0.2,0-0.4,0c-0.2,0-0.2,0,0.1,0c-0.1,0-0.2,0-0.2,0
        c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1,0-0.3-0.1-0.4-0.1c0.4,0.2,0,0-0.1,0c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.7-0.6-0.4-0.2
        c-0.3-0.3-0.6-0.5-0.9-0.8c-0.1-0.1-0.2-0.2-0.3-0.4c0,0-0.1-0.1-0.1-0.1c-0.2-0.2,0.2,0.3,0.1,0.1c-0.1-0.1-0.1-0.2-0.2-0.3
        c-0.4-0.7-1.4-1-2.1-0.6C519.2,509.6,518.9,510.6,519.3,511.3L519.3,511.3z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  id="progress_wheel_94"
                  // className={progress > 94 ? 'st42' : ''}
                  d="M497.2,524.5c0,4.5-1.9,8.8-5.2,11.9c-2.8,2.5-6.5,4.1-10.2,3.8c-0.8-0.1-1.6,0.8-1.6,1.6
        c0,0.9,0.7,1.5,1.6,1.6c7.5,0.6,14.4-4.9,17.2-11.6c0.9-2.3,1.3-4.7,1.4-7.2C500.3,522.5,497.2,522.5,497.2,524.5L497.2,524.5z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  id="progress_wheel_94"
                  // className={progress > 94 ? 'st42' : ''}
                  d="M491.6,538.3c0.5,0.9,1.2,1.8,2,2.5c1.9,1.6,4.2,2.3,6.7,1.9c1.9-0.3,3.6-1.1,5.2-2.1
        c0.7-0.5,1-1.4,0.6-2.1c-0.4-0.7-1.4-1-2.1-0.6c-0.7,0.5-1.4,0.9-2.2,1.2c0.1-0.1-0.2,0.1-0.2,0.1c-0.1,0.1-0.3,0.1-0.5,0.2
        c-0.3,0.1-0.7,0.2-1,0.3c-0.1,0-0.3,0.1-0.4,0.1c0.3,0,0,0-0.1,0c-0.3,0-0.6,0-0.9,0c-0.1,0-0.2,0-0.4,0c-0.3,0,0.2,0.1,0,0
        c-0.2-0.1-0.5-0.1-0.7-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.2-0.1,0,0c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.5-0.3
        c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.1,0.3,0.2,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.3,0.2,0.2,0-0.1
        c-0.2-0.3-0.4-0.5-0.5-0.8c-0.4-0.7-1.4-1-2.1-0.6C491.4,536.7,491.3,537.6,491.6,538.3L491.6,538.3z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  id="progress_wheel_96"
                  // className={progress > 96 ? 'st42' : ''}
                  d="M463.5,550.5c-0.9,4.5-3.4,8.4-7.3,10.8c-3.2,1.9-7.1,2.9-10.7,1.9c-1.9-0.5-2.8,2.5-0.8,3
        c4.4,1.2,9.2,0.2,13.1-2.2c4.6-2.8,7.7-7.4,8.7-12.6C466.8,549.4,463.8,548.5,463.5,550.5L463.5,550.5z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  id="progress_wheel_96"
                  // className={progress > 96 ? 'st42' : ''}
                  d="M455.3,563.1c1,3.4,4.1,5.9,7.7,6.1c1.9,0.1,3.8-0.4,5.5-1.1c0.8-0.3,1.3-1,1.1-1.9
        c-0.2-0.7-1.1-1.4-1.9-1.1c-0.8,0.3-1.6,0.6-2.4,0.8c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.5,0.1-0.2,0c-0.3,0-0.7,0.1-1,0.1
        c-0.3,0-0.6,0-0.8,0c-0.3,0,0.3,0.1-0.1,0c-0.2,0-0.3-0.1-0.5-0.1c-0.2-0.1-0.5-0.1-0.7-0.2c0,0-0.1,0-0.1-0.1
        c-0.2-0.1,0.3,0.1,0.1,0c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1,0.1,0.1,0.1,0.1
        c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.6-0.5-0.9-0.9c-0.1-0.1-0.1-0.2-0.2-0.2c0.2,0.1,0.2,0.3,0,0.1c-0.1-0.1-0.2-0.3-0.3-0.4
        c-0.2-0.3-0.3-0.5-0.4-0.8c0-0.1-0.2-0.5-0.1-0.2c0.1,0.3,0,0,0-0.1c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.8-1.1-1.3-1.9-1.1
        C455.6,561.4,455.1,562.3,455.3,563.1L455.3,563.1z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  id="progress_wheel_98"
                  // className={progress > 98 ? 'st42' : ''}
                  d="M428.9,572.4c-1.3,4.4-4.2,8-8.3,10c-3.3,1.7-7.3,2.2-10.8,0.8c-1.9-0.7-2.7,2.3-0.8,3
        c4.3,1.6,9.2,0.8,13.2-1.2c4.8-2.4,8.2-6.8,9.7-11.9C432.5,571.3,429.5,570.5,428.9,572.4L428.9,572.4z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  id="progress_wheel_98"
                  // className={progress > 98 ? 'st42' : ''}
                  d="M419.8,584.3c0.7,3.4,3.7,6.1,7.1,6.6c1.9,0.3,3.9,0,5.7-0.6c0.8-0.3,1.3-1.1,1.1-1.9
        c-0.2-0.8-1.1-1.3-1.9-1.1c-0.4,0.1-0.8,0.3-1.3,0.4c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.7,0.1-0.4,0.1c-0.7,0.1-1.4,0.1-2,0.1
        c-0.1,0-0.5-0.1-0.2,0c0.2,0-0.2,0-0.3-0.1c-0.3-0.1-0.6-0.1-0.8-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1,0.3,0.1,0.1,0
        c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.5-0.2-0.7-0.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.2,0.2,0.2,0,0c-0.2-0.2-0.3-0.3-0.5-0.5
        c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.1-0.3,0,0c0-0.1-0.1-0.2-0.2-0.2c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1-0.1-0.1-0.3-0.2-0.4
        c-0.1-0.2,0.2,0.4,0.1,0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.3-0.2-0.6-0.3-0.9c-0.2-0.8-1.1-1.3-1.9-1.1
        C420,582.6,419.7,583.5,419.8,584.3L419.8,584.3z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
      <div className="absolute flex justify-center items-center w-full h-full top-0 left-0">
        <div
          className="z-10 rounded-full w-5/6 h-5/6 overflow-hidden"
          style={{ marginLeft: '-20px' }}
        >
          {imageOverlay}
          <div className="relative w-full h-full">
            <ImageWithFallback
              src={
                typeof imageSrc === 'string' && imageSrc.startsWith('//')
                  ? `https:${imageSrc}`
                  : !imageSrc
                  ? require('../../../assets/images/Savings-Summary-Icon.svg')
                  : imageSrc
              }
              alt="Suma wealth product"
              placeholder={loading ? 'blur' : undefined}
              sizes={sizes}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressWheel;
