import React, { ElementType, forwardRef } from 'react';

import classNames from 'classnames';

export interface VerticalLayoutProps {
  gapClassName?: string;
  className?: string;
  as?: ElementType;
  children?: React.ReactNode;
  style?: any;
}

const VerticalLayout = forwardRef<HTMLDivElement, VerticalLayoutProps>(
  ({ className, gapClassName = 'gap-4', children, as = 'div', style }, ref) => {
    const C = as;
    return (
      <C
        className={classNames('flex w-full flex-col', gapClassName, className)}
        ref={ref}
        style={style}
      >
        {children}
      </C>
    );
  }
);
VerticalLayout.displayName = 'VerticalLayout';

export default VerticalLayout;
