import React, { useEffect, useState } from 'react';

import fallbackImage from '../../../assets/images/Savings-Summary-Icon.svg';

interface ImageWithFallbackProps extends Omit<ImageProps, 'src'> {
  src?: ImageProps['src'];
  fallback?: string;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  fallback = fallbackImage,
  alt,
  src,
  ...props
}) => {
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    setError(null);
  }, [src]);

  return (
    <img alt={alt} onErrorCapture={setError} src={!src || error ? fallback : src} {...props} />
  );
};

export default ImageWithFallback;
