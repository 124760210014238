import React, { useEffect, useContext, useState } from 'react';

import { ProgressContext } from '../context/ProgressContext';
import ReactEcharts from 'echarts-for-react';
import Typography from './atoms/Typography';
import classNames from 'classnames';
import ProgressWheel from './atoms/ProgressWheel/ProgressWheel';
import styles from './atoms/ProgressCircle/ProgressCircle.module.css';

const Progress = ({
  loading,
  payment,
  itemPrice,
  userId,
  goalId,
  userGoal,
  goalOwner,
  transactionsTotal,
  chipInTotal,
  complete = false,
}) => {
  let { progress, dispatch } = useContext(ProgressContext);
  const [amountWithoutChipInPercentage, setAmountWithoutChipInPercentage] = useState(0);
  const [percentageComplete, setPercentageComplete] = useState(0);

  function userPercentage(transactionsTotal) {
    const totalTargetToPay = itemPrice.toFixed(0);

    let percentage = Math.ceil((transactionsTotal / totalTargetToPay) * 100);
    dispatch({ type: 'USER_PERCENTAGE', percentage: percentage });
    dispatch({ type: 'USER_PAID', paid: transactionsTotal });
  }

  function getProductImage() {
    if (userGoal) {
      return userGoal.product.pictures.default.large;
    }

    return '';
  }
  function getProductName() {
    if (userGoal) {
      return `${userGoal.product.brand.name} ${userGoal.product.name}`;
    } else {
      return '';
    }
  }

  function capitalize(s) {
    if (s) {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    } else {
      return '';
    }
  }

  function chipInPercentage(chipInTotal) {
    if (itemPrice !== 0) {
      const totalTargetToPay = itemPrice.toFixed(0);

      let percentage = Math.ceil((chipInTotal / totalTargetToPay) * 100);
      dispatch({
        type: 'WITH_CHIP_IN_PERCENTAGE',
        percentage: percentage,
      });
      // dispatch({ type: 'WITH_CHIP_IN_PAID', paid: totalWithChipIn });
    } else {
      dispatch({ type: 'WITH_CHIP_IN_PERCENTAGE', percentage: 0.00001 });
    }
  }

  useEffect(() => {
    userPercentage(transactionsTotal);
    chipInPercentage(chipInTotal);
    setAmountWithoutChipInPercentage(((transactionsTotal - chipInTotal) / itemPrice) * 100);
  }, [payment, itemPrice, transactionsTotal, chipInTotal]);

  let variant = 'base';

  return (
    <div
      className={classNames('relative m-[1.4em] mb-[2em]', {
        'text-[33px] md:text-[60px]': variant === 'base',
        'text-[20px] md:text-[24px]': variant === 'sm',
      })}
    >
      <ProgressWheel
        lineStyle={{
          user: {
            percentage: amountWithoutChipInPercentage / 100,
          },
          chip: {
            percentage: +progress.withChipInProgress.percentage / 100,
          },
          total: {
            percentage:
              (+progress.withChipInProgress.percentage + +amountWithoutChipInPercentage) / 100,
          },
        }}
        isComplete={complete}
        progress={progress.userProgress.percentage}
        imageSrc={getProductImage()}
      />

      {!complete ? (
        <div className="items-center justify-center flex my-2">
          <div className="items-center flex flex-row my-2 mx-2">
            <div className="h-4 w-4 bg-[#60bdd3]"></div>
            <Typography variant="body-fine-print" className="ml-2">
              User's Dinero
            </Typography>
          </div>
          <div className="items-center flex flex-row my-2 mx-2">
            <div className="h-4 w-4 bg-[#dd5a57]"></div>
            <Typography variant="body-fine-print" className="ml-2">
              Chip-in Stash
            </Typography>
          </div>
        </div>
      ) : null}

      <div className="absolute left-1/2 bottom-0 flex -translate-x-1/2 translate-y-[1.8em] transform flex-col items-center justify-center gap-[0.2em]">
        <Typography variant="heading1" tag="span" className={styles['typography-override']}>
          <span
            className={classNames([styles['percentage-amount'], 'mr-[0.0666667em] leading-none'])}
          >
            {Math.ceil(progress.userProgress.percentage)}
          </span>
          <span className={classNames([styles['percentage-symbol'], 'leading-none'])}>%</span>
        </Typography>
        <Typography
          variant="label"
          className={classNames([styles['percentage-label'], styles['typography-override']])}
        >
          Saved
        </Typography>
      </div>
    </div>
  );
};

export default Progress;
