import React from 'react';
import classNames from 'classnames';
import logo from '../../../assets/images/logo-suma-azul.png';

export const HEADER_HEIGHT_CLASS = 'h-[53px] md:h-[100px]';

export const AppHeader = () => {
  return (
    <Header>
      <div className="container relative flex h-full items-center justify-between bg-opacity-90 backdrop-blur backdrop-filter">
        {/* Using this element is a little hack to left-align the logo on mobile
            and center-align the logo on desktop within a flex */}
        <div className="relative hidden opacity-0 md:block">
          {/* This element prevents users from accidentally tapping the invisible buttons on the left */}
          <div className="absolute top-0 left-0 z-10 h-full w-full" />
          <span />
        </div>
        <a
          className="flex select-none items-center cursor-pointer"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="flex w-20 select-none items-center md:w-32">
            <img className="content-center text-center" alt="Suma Wealth Logo" src={logo} />
          </div>
        </a>
        <span />
      </div>
    </Header>
  );
};

export const Header: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <header className={classNames('fixed z-50 w-screen shadow-lg bg-suma', HEADER_HEIGHT_CLASS)}>
        {children}
      </header>
      {/**
       * This is a trick to shift page content down by the same amount as the fixed header.
       * Height must match header height.
       */}
      <div className={HEADER_HEIGHT_CLASS}></div>
    </>
  );
};
